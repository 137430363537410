import { BehaviorSubject } from 'rxjs';
import { OfficeUserRole } from './../../firm/offices/officeUser';
import { Injectable } from '@angular/core';
import { User } from '../../firm/users/user';

export class Permissions {
  isAdmin: boolean;
  isOwner: boolean;
  isMaster: boolean;
  trustedAdvisor: boolean;
  canSendClientRequest: boolean;

  constructor(role, isMaster: boolean, trustedAdvisor: boolean, canSendClientRequest: boolean) {
    this.isAdmin = role >= 1;
    this.isOwner = role >= 2;
    this.isMaster = isMaster;
    this.trustedAdvisor = trustedAdvisor;
    this.canSendClientRequest = canSendClientRequest;
  }
}

export class FeatureFlags {
  bankFeed: boolean;
  ledger: boolean;

  constructor(bankFeed: boolean, ledger: boolean) {
    this.bankFeed = bankFeed;
    this.ledger = ledger;
  }
}

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  active: boolean;
  identityId: string;
  user: User;
  permissions: Permissions;
  featureFlags: FeatureFlags;

  private _featureFlagsLoaded = new BehaviorSubject<FeatureFlags | null>(null);
  featureFlagsLoaded$ = this._featureFlagsLoaded.asObservable();

  public load(identityId: string) {
    this.active = true;
    this.identityId = identityId;
  }

  public loadUser(user: User, isMaster: boolean) {
    this.user = new User(user);
    this.permissions = new Permissions(user.role, isMaster, user.trustedAdvisor, user.canSendClientRequest);
    this.featureFlags = new FeatureFlags(user.bankFeed, user.ledger)
    this.active = this.active && !this.user.inactive;

    this._featureFlagsLoaded.next(this.featureFlags);
  }

  public destroy() {
    this.active = false;
    this.identityId = null;
    this.user = null;
  }
}
