<div class="modal-header">
  <h4 class="modal-title">
    Ledger Report
  </h4>
  <button type="button" class="close" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">

  <crs-ledger-report [datasetId]="params.datasetId" [filters]="params.filters" [triggerImmediately]="true"></crs-ledger-report>

</div>

<div class="modal-footer">
  <button type="button" class="btn btn-secondary" (click)="close()">Close</button>
</div>


