import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { EMPTY, Observable, Subject, Subscription, of } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  exhaustMap,
  finalize,
  tap,
} from 'rxjs/operators';

import { MessageService } from 'src/app/core';
import {
  TrialBalanceHeaderGroupComponent,
  accountingRenderers,
  getDefaultGridOptions,
} from 'src/app/shared';
import { JournalService } from '../../ledger/journals/journal.service';
import { TrialBalanceReportRow } from '../../ledger/ledgerReports/trialBalanceReportRow';
import { ConsolidationMode } from '../../reports/reportTemplates';
import { Dataset } from '../dataset';
import { DatasetService } from '../dataset.service';
import {
  JournalCategory,
  JournalCategoryWithTrialBalanceOption,
} from './../../ledger/journals/journalCategory';
import { GroupByAccountType } from './../../ledger/ledgerReports/groupByAccountType';
import { LedgerReportService } from './../../ledger/ledgerReports/ledger-report.service';
import { ActiveFileService } from '../../active-file.service';
import { ColDef, ColGroupDef } from 'ag-grid-community';

enum Action {
  Update,
  ManualExportJournal,
  AutoExportJournal,
}

@Component({
  selector: 'crs-dataset-trial-balances',
  templateUrl: './dataset-trial-balances.component.html',
  styleUrls: ['./dataset-trial-balances.component.scss'],
})
export class DatasetTrialBalancesComponent implements OnInit, OnDestroy {
  fileId: string;
  datasetId: string;
  datasetName: string;
  busy = {
    update: null,
    exportJournal: null,
  };

  form = this.formBuilder.group({
    journalCategory: [
      JournalCategoryWithTrialBalanceOption.TrialBalance,
      Validators.required,
    ],
    groupByAccountType: [GroupByAccountType.Account, Validators.required],
    startDate: [null, Validators.required],
    endDate: [null, Validators.required],
    consolidationMode: [ConsolidationMode.None, Validators.required],
    comparativeDataset: [null],
  });
  search = new UntypedFormControl();

  trialBalance: TrialBalanceReportRow[];
  trialBalanceTotal = [this.calculateTotals([])];

  isGroupDataset = false;
  showExportButton = false;
  canAutoExport = false;
  error: string;

  actionStream$ = new Subject<Action>();
  renderers = accountingRenderers;
  subscriptions: Subscription[] = [];

  gridOptions = getDefaultGridOptions();
  groupByAccountTypes = GroupByAccountType;
  journalCategories = JournalCategoryWithTrialBalanceOption;
  consolidationModes = ConsolidationMode;

  comparativeDatasets: Dataset[];

  constructor(
    private activeFileService: ActiveFileService,
    private datasetService: DatasetService,
    private formBuilder: UntypedFormBuilder,
    private journalService: JournalService,
    private ledgerReportService: LedgerReportService,
    private messageService: MessageService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.customiseGridOptions();
    this.configureActionStream();

    this.route.params.subscribe(() => {
      this.datasetId = this.route.snapshot.parent.parent.paramMap.get('id');
      const category = this.route.snapshot.queryParamMap.get('journalCategory');

      if (category) {
        this.form.get('journalCategory').setValue(parseInt(category, 10));
      }

      this.getAndApplyDatasetInfo$()
        .pipe(
          exhaustMap(() => this.getApiFeatures$()),
          tap(() => this.getReport())
        )
        .subscribe();
    });

    this.subscriptions.push(
      this.activeFileService.stream.subscribe((file) => {
        this.fileId = file.id;
      })
    );

    this.fetchComparativeDatasets();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  configureActionStream(): void {
    this.actionStream$
      .pipe(
        tap(() => (this.error = null)),
        exhaustMap((action) =>
          action === Action.Update
            ? this.getReport$()
            : this.exportObservable(action)
        ),
        catchError((error) => {
          this.showError(error);
          return EMPTY;
        })
      )
      .subscribe();
  }

  getReport(): void {
    this.actionStream$.next(Action.Update);
  }

  getReport$() {
    let category = this.form.controls.journalCategory
      .value as JournalCategoryWithTrialBalanceOption;

    if (category === JournalCategoryWithTrialBalanceOption.TrialBalance) {
      category = null;
    }

    const accountType = this.form.controls.groupByAccountType
      .value as GroupByAccountType;
    const startDate = this.form.controls.startDate.value as Date;
    const endDate = this.form.controls.endDate.value as Date;
    const consolidationMode = this.form.controls.consolidationMode
      .value as ConsolidationMode;
    const comparativeDatasetId = this.form.controls.comparativeDataset.value
      ?.id as string;

    const loading$ = new Subject();
    this.busy.update = loading$.subscribe();

    return this.ledgerReportService
      .getTrialBalance$(
        this.datasetId,
        accountType,
        startDate,
        endDate,
        (<any>category) as JournalCategory,
        consolidationMode,
        [comparativeDatasetId]
      )
      .pipe(
        catchError((err) => {
          this.showError(err);
          return of([]);
        }),
        tap((data) => {
          this.trialBalanceTotal = [this.calculateTotals(data)];
          this.trialBalance = data;
          this.showExportButton = false; //category === JournalCategoryWithTrialBalanceOption.PendingExport &&
          //accountType === GroupByAccountType.SourceAccount;

          const columnsWithoutComparatives: ColDef[] = [
            this.getAccountNoColumn(),
            this.getAccountNameColumn(),
            this.getClassificationColumn(),
            this.getDebitColumn(),
            this.getCreditColumn(),
            this.getQuantityColumn(),
          ];

          const columnsWithComparatives: (ColDef | ColGroupDef)[] = [
            this.getAccountNoColumn(),
            this.getAccountNameColumn(),
            this.getClassificationColumn(),
            ...this.getCurrentColumns(),
          ];

          columnsWithComparatives.push(
            ...this.getComparativeColumns(comparativeDatasetId)
          );

          const newColumnDefs =
            this.form.controls.comparativeDataset.value === null
              ? columnsWithoutComparatives
              : columnsWithComparatives;

          this.gridOptions.api?.setColumnDefs(newColumnDefs);
        }),
        finalize(() => loading$.complete())
      );
  }

  getAndApplyDatasetInfo$(): Observable<Dataset> {
    return this.datasetService.getLight$(this.datasetId).pipe(
      tap((dataset) => {
        this.datasetName = dataset.name;

        if (dataset.isGroupDataset) {
          this.isGroupDataset = true;
        }

        if (this.isGroupDataset) {
          this.form.controls['groupByAccountType'].setValue(
            GroupByAccountType.Account
          );
        }

        this.form.controls['startDate'].patchValue(dataset.startDate);
        this.form.controls['endDate'].patchValue(dataset.endDate);
      }),
      catchError((error) => {
        this.showError(error);
        return EMPTY;
      })
    );
  }

  getApiFeatures$() {
    return this.datasetService.getApiFeatures$(this.datasetId).pipe(
      tap((apiFeatures) => {
        this.canAutoExport = apiFeatures.canExportJournals;
      }),
      catchError((error) => {
        this.canAutoExport = false;
        console.error('Error retrieving api features', error);
        return of(null);
      })
    );
  }

  calculateTotals(rows: TrialBalanceReportRow[]) {
    let debit = 0;
    let credit = 0;
    let quantity = 0;

    rows.forEach((row) => {
      debit += row.debit;
      credit += row.credit;
      quantity += row.quantity;
    });

    return {
      debit,
      credit,
      quantity,
    };
  }

  customiseGridOptions(): void {
    const columnDefs: (ColDef | ColGroupDef)[] = [
      this.getAccountNoColumn(),
      this.getAccountNameColumn(),
      this.getClassificationColumn(),
      this.getDebitColumn(),
      this.getCreditColumn(),
      this.getQuantityColumn(),
    ];

    this.gridOptions.columnDefs = columnDefs;
    this.gridOptions.suppressHorizontalScroll = true;
    this.gridOptions.enableRangeSelection = true;
    this.gridOptions.suppressCellSelection = false;
    this.gridOptions.frameworkComponents = accountingRenderers;
    this.gridOptions.groupIncludeTotalFooter = true;
    this.gridOptions.groupHeaderHeight = 75;

    this.search.valueChanges
      .pipe(debounceTime(200), distinctUntilChanged())
      .subscribe((data) => {
        if (this.gridOptions.api) {
          this.gridOptions.api.setQuickFilter(data);
        }
      });
  }

  export(auto: boolean): void {
    if (auto) {
      this.actionStream$.next(Action.AutoExportJournal);
    } else {
      this.actionStream$.next(Action.ManualExportJournal);
    }
  }

  exportObservable(action: Action) {
    const loading$ = new Subject();
    this.busy.exportJournal = loading$.subscribe();

    return this.journalService
      .postBalancingJournal$(
        this.datasetId,
        action === Action.AutoExportJournal
      )
      .pipe(
        exhaustMap(() => {
          this.messageService.success(
            'Successfully processed balancing journal.'
          );
          return this.getReport$();
        }),
        catchError((err) => {
          this.showError(err);
          return EMPTY;
        }),
        finalize(() => loading$.complete())
      );
  }

  private showError(error): void {
    this.messageService.error(error);
  }

  private fetchComparativeDatasets(): void {
    this.datasetService
      .getAll$(this.fileId)
      .pipe(
        catchError((error) => {
          this.showError(error);
          return of([]);
        }),
        tap((datasets: Dataset[]) => {
          this.comparativeDatasets = datasets.filter(
            ({ id }) => id !== this.datasetId
          );
        })
      )
      .subscribe();
  }

  private getAccountNoColumn(): ColDef {
    return {
      colId: 'accountNo',
      field: 'accountNo',
      headerName: 'Account No',
      width: 110,
    };
  }

  private getAccountNameColumn(): ColDef {
    return {
      colId: 'accountName',
      field: 'accountName',
      headerName: 'Account Name',
      flex: 3,
    };
  }

  private getClassificationColumn(): ColDef {
    return {
      cellRendererFramework: accountingRenderers.classificationRenderer,
      colId: 'classification',
      field: 'classificationToolTipText',
      tooltipField: 'classificationToolTipText',
      headerName: 'Class',
      width: 110,
    };
  }

  private getDebitColumn(datasetId?: string, showLeftBorder?: boolean): ColDef {
    return {
      colId: 'debit',
      field: datasetId ? `comparatives.${datasetId}.debit` : 'debit',
      headerName: 'Debit',
      type: 'dollarColumn',
      cellClass: ['dollar-cell', 'ag-numeric-cell', 'highlight-column'],
      ...(showLeftBorder && { headerClass: ['centered', 'border-left-cell'] }),
      ...(showLeftBorder && {
        cellStyle: { 'border-left': '1px solid #F2F5F6' },
      }),
    };
  }

  private getCreditColumn(datasetId?: string): ColDef {
    return {
      colId: 'credit',
      field: datasetId ? `comparatives.${datasetId}.credit` : 'credit',
      headerName: 'Credit',
      type: 'dollarColumn',
      cellClass: ['dollar-cell', 'ag-numeric-cell', 'highlight-column'],
    };
  }

  private getQuantityColumn(
    datasetId?: string,
    showRightBorder?: boolean
  ): ColDef {
    return {
      colId: 'quantity',
      field: datasetId ? `comparatives.${datasetId}.quantity` : 'quantity',
      headerName: 'Quantity',
      type: 'numberColumn',
      minWidth: 100,
      width: 120,
      ...(showRightBorder && {
        headerClass: ['border-right-cell', 'centered'],
      }),
      ...(showRightBorder && {
        cellStyle: { 'border-right': '1px solid #F2F5F6' },
      }),
    };
  }

  private getCurrentColumns(): ColGroupDef[] {
    return [
      {
        headerName: `(Balance) ${this.datasetName}`,
        headerClass: [
          'ag-header-group-cell-label',
          'centered',
          'comparative-header-group',
        ],
        headerGroupComponentFramework: TrialBalanceHeaderGroupComponent,
        headerGroupComponentParams: {
          datasetName: this.datasetName,
          heading: 'Balance',
        },
        marryChildren: true,
        children: [
          this.getDebitColumn(null, true),
          this.getCreditColumn(),
          this.getQuantityColumn(null, true),
        ],
      },
    ];
  }

  private getComparativeColumns(datasetId?: string): ColGroupDef[] {
    const comparativeDataset = this.comparativeDatasets.find(
      ({ id }) => id === datasetId
    );

    return [
      {
        headerName: `(Comparative) ${comparativeDataset?.name}`,
        headerClass: [
          'ag-header-group-cell-label',
          'centered',
          'comparative-header-group',
        ],
        headerGroupComponentFramework: TrialBalanceHeaderGroupComponent,
        headerGroupComponentParams: {
          datasetName: comparativeDataset?.name,
          heading: 'Comparative',
        },
        marryChildren: true,
        children: [
          this.getDebitColumn(datasetId, true),
          this.getCreditColumn(datasetId),
          this.getQuantityColumn(datasetId, true),
        ],
      },
    ];
  }
}
