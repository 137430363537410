<div class="row">
  <div class="col-12">
    <crs-busy *ngIf="busy.depreciationYears"></crs-busy>

    <crs-form-error [error]="error"></crs-form-error>

    <div class="btn-menu pad-x">
      <button
        class="btn btn-secondary"
        title="Create Division"
        type="button"
        (click)="addDepreciationYear()"
      >
        <i aria-hidden="true" class="fas fa-plus inset"></i>
        New Depreciation Year
      </button>
    </div>

    <div>
      <ag-grid-angular
        #agGrid
        class="ag-theme-material ag-grid-full"
        rowClass="selectable-row"
        [gridOptions]="gridOptions"
        [rowData]="context?.yearsDescending"
        (rowClicked)="selectDepreciationYear($event)"
      >
        <ag-grid-column field="year" headerName="Year"></ag-grid-column>
        <ag-grid-column
          field="startDate"
          headerName="Start Date"
          type="dateColumn"
        ></ag-grid-column>
        <ag-grid-column
          field="endDate"
          headerName="End Date"
          type="dateColumn"
        ></ag-grid-column>
        <ag-grid-column field="taxYear" headerName="Tax Year"></ag-grid-column>
        <ag-grid-column
          field="useSmallBusinessDepreciation"
          headerName="SBR"
          type="booleanColumn"
          [maxWidth]="120"
          [minWidth]="120"
        ></ag-grid-column>
        <ag-grid-column
          field="isLocked"
          headerName="Locked"
          type="booleanColumn"
          [cellRendererParams]="{ iconClass: 'fas fa-lock' }"
          [maxWidth]="120"
          [minWidth]="120"
        ></ag-grid-column>
      </ag-grid-angular>
    </div>
  </div>
</div>
