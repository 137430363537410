<ng-container *ngIf="status$ | async as status; else loading">
  <ng-container [ngSwitch]="status">
    <ng-container *ngSwitchCase="'ERROR'" [ngTemplateOutlet]="error">
    </ng-container>
    <ng-container *ngSwitchDefault [ngTemplateOutlet]="success"> </ng-container>
  </ng-container>
</ng-container>

<ng-template #loading>
  <crs-busy></crs-busy>
</ng-template>

<ng-template #error>
  <crs-error-view [message]="error$ | async"></crs-error-view>
</ng-template>

<ng-template #success>
  <div>
    <ag-grid-angular
      class="ag-theme-material rule-table-container"
      [gridOptions]="ruleGrid.gridOptions"
      [rowDragManaged]="true"
    >
    </ag-grid-angular>
  </div>

  <div class="text-right mt-4 pad-x">
    <span class="fas fa-info-circle"></span> Rules are processed in order, drag
    a rule to change its order and precedence
    <br />
    <i style="font-size: 13px"
      >The order function will be deactivated in the presence of an active
      search filter</i
    >
  </div>
</ng-template>

<ng-template #titleCell let-rule>
  <div class="row title-cell">
    <div class="col-12">
      <div class="light-border-bottom pb-2 pt-2">{{ rule.name }}</div>
    </div>
    <div class="col-12 pb-2 pt-2">
      <div><b>Match transaction where:</b></div>
      <div class="info-text">{{ getRuleDescriptionCondition(rule) }}</div>
      <div class="info-text" *ngIf="getRuleAmountCondition(rule) as value">
        {{ value }}
      </div>
    </div>
  </div>
</ng-template>

<ng-template #accountCell let-rule>
  <div class="row account-cell">
    <div
      class="col-12 pt-2"
      *ngIf="getAmountAccountConditions(rule) as conditions"
    >
      <div
        class="d-flex justify-content-between"
        *ngFor="let condition of conditions; let i = index"
        [ngClass]="{ 'pb-2': i < conditions.length - 1 }"
      >
        <div class="info-text">${{ condition.amount }}</div>
        <div class="info-text">{{ condition.account }}</div>
      </div>
      <div class="light-border-top mt-2 pt-2 pb-2"><b>Remainder</b></div>
    </div>
    <div
      class="col-12 pb-2 pt-3"
      *ngIf="getPercentageAccountConditions(rule) as conditions"
    >
      <div
        class="h-100 d-flex justify-content-between align-items-center"
        *ngFor="let condition of conditions; let i = index"
        [ngClass]="{ 'pb-2': i < conditions.length - 1 }"
      >
        <div class="info-text">{{ condition.percentage }}%</div>
        <div class="info-text">{{ condition.account }}</div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #optionsCell let-rule>
  <div class="d-flex justify-content-end h-100">
    <button
      class="btn-sm fas-btn-primary-ghost"
      type="button"
      (click)="onEditRule(rule)"
    >
      <i class="fas fa-edit"></i>
    </button>
    <button
      class="btn-sm fas-btn-ghost"
      type="button"
      (click)="onDeleteRule(rule.id)"
    >
      <i aria-hidden="true" class="fas fa-trash"></i>
    </button>
  </div>
</ng-template>
