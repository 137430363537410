export class Config {
  public static version = '0.0.0';
  public static baseUrls = {
    api:  'https://null',
    site: 'https://null',
    addin: 'https://null'
  };
  public static identity = {
    clientId: '',
    issuer: '',
    disoveryDocumentUrl: '',
    redirectUri: '',
    scope: '',
  }
  static sockets: {
    addinApi: 'wss://null'
  };

  static loadConfig(config: any) {
    this.version = config.version;
    this.baseUrls = config.baseUrls;
    this.identity = config.identity;
    this.sockets = config.sockets;
  }
}
