<div [formGroup]="formGroup">
  <div class="form-group in-line-switch adv-setting-checkbox" id="adv-setting-slider">
    <label class="switch">
      <input type="checkbox" class="form-control checkbox" (click)="switchClicked($event)" formControlName="isAuditorStatusEnabled">
          <span class="slider">
                <span class="on">ON</span>
                <span class="no">OFF</span>
          </span>
      </label>
    </div>
</div>
