import { DownloadService } from './../../../../core/services/download.service';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, FormControl, Validators } from '@angular/forms';
import { Subscription, merge, Observable, of } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';

import { DepreciationReportType } from './../depreciation-report-type';
import { DepreciationPool } from './../../assets/depreciation-pool';
import { AssetsContext } from '../../assets-context';
import { AssetReportService } from './../asset-report.service';
import { AssetsContextService } from './../../assets-context.service';
import { DepreciationType } from '../../assets/depreciation-type';
import { AssetGroup } from '../../asset-groups/asset-group';

enum OutputType {
  FullSummary = 0,
  Detail = 1,
  Pool = 2,
  Taxation = 3
}

@Component({
  selector: 'crs-asset-reports',
  templateUrl: './asset-reports.component.html',
  styleUrls: ['./asset-reports.component.scss']
})
export class AssetReportsComponent implements OnInit, OnDestroy {

  error = null;

  busy = {
    update: null,
    excel: null
  };

  depreciationReportTypes = DepreciationReportType;
  depreciationPools = DepreciationPool;
  depreciationTypes = DepreciationType;

  form = this._formBuilder.group({
    depreciationReport: [DepreciationReportType.FullSummary, Validators.required],
    assetGroups: [[]],
    depreciationPool: [DepreciationPool.GeneralPool],
    depreciationType: [DepreciationType.Taxation]
  });

  subscriptions: Subscription[] = [];

  assetsContext: AssetsContext;

  output: any | any[];
  outputType: OutputType;

  constructor(private readonly _formBuilder: UntypedFormBuilder,
    private readonly _assetsContextService: AssetsContextService,
    private readonly _reportService: AssetReportService,
    private readonly _downloadService: DownloadService,
    readonly route: ActivatedRoute) {
  }

  ngOnInit() {
    this.subscriptions.push(
      this._assetsContextService.contextValid$
      .pipe(
        tap(c => this.assetsContext = c)
      )
      .subscribe()
    );

    this.subscriptions.push(
      this.form.controls['depreciationReport'].valueChanges.pipe(
        tap(t => {
          this.form.controls['depreciationPool'].setValidators(t === DepreciationReportType.Pooling ? Validators.required : null);
          this.form.controls['depreciationType'].setValidators(t === DepreciationReportType.Pooling ? Validators.required : null);
        })
      )
      .subscribe()
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
    this.subscriptions = [];
  }

  getReport() {
    this.error = null;
    this.output = null;
    this.outputType = null;

    const type = this.form.controls['depreciationReport'].value as DepreciationReportType;
    const groups = this.form.controls['assetGroups'].value as AssetGroup[];
    const pool = this.form.controls['depreciationPool'].value as DepreciationPool;
    const depreciationType  = this.form.controls['depreciationType'].value as DepreciationType;

    let query: Observable<any>;
    switch (type) {
      case DepreciationReportType.FullSummary:
        query = this._reportService.getFullSummary(this.assetsContext, groups.map(g => g.id));
        break;
      case DepreciationReportType.Taxation:
        query = this._reportService.getDetail(this.assetsContext, groups.map(g => g.id), DepreciationType.Taxation);
        break;
      case DepreciationReportType.Accounting:
        query = this._reportService.getDetail(this.assetsContext, groups.map(g => g.id), DepreciationType.Accounting);
        break;
      case DepreciationReportType.Pooling:
        query = this._reportService.getPool(this.assetsContext, pool, depreciationType);
        break;
      case DepreciationReportType.TaxDisclosures:
        query = this._reportService.getTaxReturn(this.assetsContext);
        break;
    }

    this.busy.update = query.pipe(
      tap(r => this.output = r),
      tap(r => this.outputType = this.getOutputType(type)),
      catchError(e => {
        this.error = e;
        return of(null);
      })
    ).subscribe();

  }

  exportToExcel() {
    this.error = null;

    const type = this.form.controls['depreciationReport'].value as DepreciationReportType;
    const groups = this.form.controls['assetGroups'].value as AssetGroup[];
    const pool = this.form.controls['depreciationPool'].value as DepreciationPool;
    const depreciationType  = this.form.controls['depreciationType'].value as DepreciationType;

    let query: Observable<any>;
    switch (type) {
      case DepreciationReportType.FullSummary:
        query = this._reportService.getFullSummaryExcel(this.assetsContext, groups.map(g => g.id));
        break;
      case DepreciationReportType.Taxation:
        query = this._reportService.getDetailExcel(this.assetsContext, groups.map(g => g.id), DepreciationType.Taxation);
        break;
      case DepreciationReportType.Accounting:
        query = this._reportService.getDetailExcel(this.assetsContext, groups.map(g => g.id), DepreciationType.Accounting);
        break;
      case DepreciationReportType.Pooling:
        query = this._reportService.getPoolExcel(this.assetsContext, pool, depreciationType);
        break;
    }

    this.busy.excel = query.pipe(
      tap(d => this._downloadService.download(d)),
      catchError(e => {
        this.error = e;
        return of(null);
      })
    ).subscribe();

  }

  private getOutputType(type: DepreciationReportType): OutputType {
    switch (type) {
      case DepreciationReportType.FullSummary: return OutputType.FullSummary;
      case DepreciationReportType.Taxation: return OutputType.Detail;
      case DepreciationReportType.Accounting: return OutputType.Detail;
      case DepreciationReportType.Pooling: return OutputType.Pool;
      case DepreciationReportType.TaxDisclosures: return OutputType.Taxation;
    }
  }

}
