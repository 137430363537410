<div *ngIf="!route.children.length">
  <crs-form-error [error]="error"></crs-form-error>

  <!-- Display Options -->
  <form class="pad-x" novalidate [formGroup]="form" (validSubmit)="getReport()">
    <div class="row">
      <div class="col-sm-6">
        <div class="form-group required">
          <label class="control-label">Report</label>
          <ng-select
            bindLabel="name"
            bindValue="value"
            formControlName="depreciationReport"
            [clearable]="false"
            [items]="depreciationReportTypes | enumToArray"
          ></ng-select>
        </div>
      </div>

      <div class="col-sm-6">
        <div class="form-group" *ngIf="form.value.depreciationReport < 3">
          <label class="control-label">Asset Groups</label>
          <crs-asset-group-select
            formControlName="assetGroups"
            placeholder="Show All"
            [multiple]="true"
          ></crs-asset-group-select>
        </div>

        <div
          class="form-group required"
          *ngIf="form.value.depreciationReport == 3"
        >
          <label class="control-label">Pool</label>
          <ng-select
            bindLabel="name"
            bindValue="value"
            formControlName="depreciationPool"
            [clearable]="false"
            [items]="depreciationPools | enumToArray"
          ></ng-select>
        </div>

        <div
          class="form-group required"
          *ngIf="form.value.depreciationReport == 3"
        >
          <label class="control-label">Depreciation Type</label>
          <ng-select
            bindLabel="name"
            bindValue="value"
            formControlName="depreciationType"
            [clearable]="false"
            [items]="depreciationTypes | enumToArray"
          ></ng-select>
        </div>
      </div>
    </div>

    <!-- Buttons -->
    <div class="btn-menu">
      <button
        class="btn btn-primary"
        type="submit"
        [disabled]="!assetsContext"
        [promiseBtn]="busy.update"
      >
        Run Report
      </button>
      <button
        class="btn btn-outline-primary"
        type="button"
        *ngIf="!form.invalid && form.value.depreciationReport < 4"
        [disabled]="!assetsContext"
        [promiseBtn]="busy.excel"
        (click)="exportToExcel()"
      >
        <i aria-hidden="true" class="fas fa-file-excel"></i>
        Export to Excel
      </button>
    </div>
  </form>

  <div class="spacer-lg"></div>

  <!-- Report Contents -->
  <div class="pad-x">
    <ng-container [ngSwitch]="outputType">
      <crs-asset-report-full-summary
        *ngSwitchCase="0"
        [content]="output"
      ></crs-asset-report-full-summary>
      <crs-asset-report-detail
        *ngSwitchCase="1"
        [content]="output"
      ></crs-asset-report-detail>
      <crs-asset-report-pool
        *ngSwitchCase="2"
        [content]="output"
        [poolType]="form.controls['depreciationPool'].value"
      ></crs-asset-report-pool>
      <crs-asset-report-tax-return
        *ngSwitchCase="3"
        [content]="output"
        [smallBusinessDisclosures]="
          assetsContext.year.useSmallBusinessDepreciation
        "
      ></crs-asset-report-tax-return>
    </ng-container>
  </div>
</div>

<router-outlet *ngIf="route.children.length"></router-outlet>
