import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'crs-slider-checkbox',
  templateUrl: './slider-checkbox.component.html',
  styleUrls: ['./slider-checkbox.component.scss']
})
export class SliderCheckboxComponent implements OnInit {
  @Input('formGroup') formGroup: UntypedFormGroup;

  constructor() {
  }

  ngOnInit(): void {
  }

  switchClicked(event) {
    let statusValue =  event.target.checked;
    this.formGroup.patchValue({"isAuditorStatusEnabled": JSON.parse(statusValue)});
  }
}
