import { Classification } from '../classifications';

export class TrialBalanceReportRow {
  id: string;
  classification: Classification;
  classificationToolTipText: string;
  accountNo: string;
  accountName: string;
  accountTypeId: number;
  balance: number;
  quantity: number;

  get debit() {
    return this.balance >= 0 ? this.balance : 0;
  }

  get credit() {
    return this.balance < 0 ? -this.balance : 0;
  }

  comparatives: Record<string, ComparativeTrialBalanceReportRow> = {};

  constructor(data) {
    Object.assign(this, data);

    if (data.accountTypeId != null) {
      this.accountTypeId = parseInt(data.accountTypeId, 10);
    }

    this.classificationToolTipText = this.classification
      ? Classification[this.classification]
      : '';

    // Calculate debit and credit for comparatives during initialization
    for (const key in this.comparatives) {
      if (this.comparatives.hasOwnProperty(key)) {
        this.comparatives[key] = new ComparativeTrialBalanceReportRow(
          this.comparatives[key]
        );
      }
    }
  }
}

export class ComparativeTrialBalanceReportRow {
  balance: number;
  quantity: number;

  get debit() {
    return this.balance >= 0 ? this.balance : 0;
  }

  get credit() {
    return this.balance < 0 ? -this.balance : 0;
  }

  constructor(data) {
    Object.assign(this, data);
  }
}
