import { CommonModule, DecimalPipe, PercentPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import Flow from '@flowjs/flow.js';
import { FlowInjectionToken, NgxFlowModule } from '@flowjs/ngx-flow';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgOptionHighlightModule } from '@ng-select/ng-option-highlight';
import { NgSelectModule } from '@ng-select/ng-select';
import { AgGridModule } from 'ag-grid-angular';
import { Angular2PromiseButtonModule } from 'angular2-promise-buttons';
import { BusyConfig, NgBusyModule } from 'ng-busy';
import { NgxCurrencyModule } from 'ngx-currency';
import { GravatarModule } from 'ngx-gravatar';

import { AppRoutingModule } from '../app-routing.module';
import { MaterialModule } from '../material.module';
import {
  AccountDetailRendererComponent,
  AccountHeaderRendererComponent,
  AccountHeadersCountRendererComponent,
  AgGridColumnDirective,
  BadgeRendererComponent,
  CheckRendererComponent,
  ClassificationRendererComponent,
  DateEditorComponent,
  DateRendererComponent,
  DivisionRendererComponent,
  DollarRendererComponent,
  EnumRendererComponent,
  HyperlinkRendererComponent,
  NumberRendererComponent,
  SelectRendererComponent,
  SourceRendererComponent,
  SourceTypeRendererComponent,
  TemplateRendererComponent,
  TrialBalanceHeaderGroupComponent,
  TypeAheadEditorComponent,
  UserRendererComponent,
} from './ag-grid';
import { BusyButtonDirective } from './busy/busy-button.directive';
import { BusyTemplateComponent } from './busy/busy-template.component';
import { BusyComponent } from './busy/busy.component';
import { busyConfigFactory } from './busy/busyConfigFactory';
import {
  ConfirmationComponent,
  CustomizedConfirmationComponent,
  DatePickerComponent,
  ErrorViewComponent,
  ProgressBarComponent,
} from './components';
import { CheckBoxComponent } from './components/check-box/check-box.component';
import { LuxonDateAdapter } from './components/date-picker/luxon-date-adapter';
import { FileDragDropUploadComponent } from './components/file-dragdrop-upload/file-dragdrop-upload.component';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { FormErrorComponent } from './components/form-error/form-error.component';
import { SliderCheckboxComponent } from './components/slider-checkbox/slider-checkbox.component';
import { StepProgressBarComponent } from './components/step-progress-bar/step-progress-bar.component';
import { TruncatedNameComponent } from './components/truncated-name/truncated-name.component';
import { ResizableDirective } from './directives';
import { AutoFocusDirective } from './directives/auto-focus.directive';
import { BusinessNumberDirective } from './directives/businessNumber.directive';
import { MouseEventsDirective } from './directives/mouse-events.directive';
import { PagingComponent } from './paging/paging.component';
import { BusinessNumberPipe } from './pipes/businessNumber.pipe';
import { EnumToArrayPipe } from './pipes/enum-to-array.pipe';
import { FillPipe } from './pipes/fill.pipe';
import { FormatNumberPipe } from './pipes/format-number.pipe';
import { FormatPercentPipe } from './pipes/format-percent.pipe';
import { InitCapsPipe } from './pipes/init-caps.pipe';
import { MoneyPipe } from './pipes/money.pipe';
import { SafePipe } from './pipes/safe.pipe';
import { SentencePipe } from './pipes/sentence.pipe';
import { NgBootstrapFormValidationModule } from './validation';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MaterialModule,
    NgBusyModule,
    Angular2PromiseButtonModule.forRoot(),
    NgSelectModule,
    NgOptionHighlightModule,
    AppRoutingModule,
    NgBootstrapFormValidationModule,
    NgbModule,
    GravatarModule,
    AgGridModule.withComponents([
      AccountDetailRendererComponent,
      AccountHeaderRendererComponent,
      AccountHeadersCountRendererComponent,
      BadgeRendererComponent,
      CheckRendererComponent,
      ClassificationRendererComponent,
      DateEditorComponent,
      DateRendererComponent,
      DivisionRendererComponent,
      DollarRendererComponent,
      EnumRendererComponent,
      HyperlinkRendererComponent,
      NumberRendererComponent,
      SelectRendererComponent,
      SourceRendererComponent,
      SourceTypeRendererComponent,
      TemplateRendererComponent,
      TypeAheadEditorComponent,
      UserRendererComponent,
    ]),
    NgxCurrencyModule.forRoot({
      align: 'right',
      allowNegative: true,
      allowZero: true,
      decimal: '.',
      precision: 2,
      prefix: '',
      suffix: '',
      thousands: ',',
      nullable: true,
      min: null,
      max: null,
      inputMode: 1, // NATURAL
    }),
    NgxFlowModule,
  ],
  declarations: [
    AccountDetailRendererComponent,
    AccountHeaderRendererComponent,
    AccountHeadersCountRendererComponent,
    AgGridColumnDirective,
    AutoFocusDirective,
    BadgeRendererComponent,
    BusinessNumberDirective,
    BusinessNumberPipe,
    BusyButtonDirective,
    BusyComponent,
    BusyTemplateComponent,
    CheckBoxComponent,
    CheckRendererComponent,
    ClassificationRendererComponent,
    ConfirmationComponent,
    CustomizedConfirmationComponent,
    DateEditorComponent,
    DatePickerComponent,
    DateRendererComponent,
    DivisionRendererComponent,
    DollarRendererComponent,
    EnumRendererComponent,
    EnumToArrayPipe,
    ErrorViewComponent,
    FileDragDropUploadComponent,
    FileUploadComponent,
    FillPipe,
    FormatNumberPipe,
    FormatPercentPipe,
    FormErrorComponent,
    HyperlinkRendererComponent,
    InitCapsPipe,
    MoneyPipe,
    MouseEventsDirective,
    NumberRendererComponent,
    PagingComponent,
    ProgressBarComponent,
    ResizableDirective,
    SafePipe,
    SelectRendererComponent,
    SentencePipe,
    SliderCheckboxComponent,
    SourceRendererComponent,
    SourceTypeRendererComponent,
    StepProgressBarComponent,
    TemplateRendererComponent,
    TrialBalanceHeaderGroupComponent,
    TruncatedNameComponent,
    TypeAheadEditorComponent,
    UserRendererComponent,
  ],
  bootstrap: [TypeAheadEditorComponent],
  providers: [
    DecimalPipe,
    PercentPipe,
    MoneyPipe,
    EnumToArrayPipe,
    {
      provide: BusyConfig,
      useFactory: busyConfigFactory,
    },
    {
      provide: FlowInjectionToken,
      useValue: Flow,
    },
    {
      provide: DateAdapter,
      useClass: LuxonDateAdapter,
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        parse: {
          dateInput: 'dd MMM yyyy',
        },
        display: {
          dateInput: 'dd MMM yyyy',
          monthYearLabel: 'MMM yyyy',
          dateA11yLabel: 'LL',
          monthYearA11yLabel: 'MMMM yyyy',
        },
      },
    },
  ],
  exports: [
    AccountDetailRendererComponent,
    AccountHeaderRendererComponent,
    AccountHeadersCountRendererComponent,
    AgGridColumnDirective,
    AgGridModule,
    Angular2PromiseButtonModule,
    AppRoutingModule,
    AutoFocusDirective,
    BadgeRendererComponent,
    BusinessNumberDirective,
    BusinessNumberPipe,
    BusyButtonDirective,
    BusyComponent,
    CheckBoxComponent,
    CheckRendererComponent,
    ClassificationRendererComponent,
    CommonModule,
    ConfirmationComponent,
    DateEditorComponent,
    DatePickerComponent,
    DateRendererComponent,
    DivisionRendererComponent,
    DollarRendererComponent,
    EnumRendererComponent,
    EnumToArrayPipe,
    ErrorViewComponent,
    FileDragDropUploadComponent,
    FileUploadComponent,
    FillPipe,
    FormatNumberPipe,
    FormatPercentPipe,
    FormErrorComponent,
    FormsModule,
    GravatarModule,
    HyperlinkRendererComponent,
    InitCapsPipe,
    MaterialModule,
    MoneyPipe,
    MouseEventsDirective,
    NgbModule,
    NgBootstrapFormValidationModule,
    NgBusyModule,
    NgOptionHighlightModule,
    NgSelectModule,
    NgxCurrencyModule,
    NgxFlowModule,
    NumberRendererComponent,
    PagingComponent,
    ProgressBarComponent,
    ReactiveFormsModule,
    ResizableDirective,
    SafePipe,
    SelectRendererComponent,
    SentencePipe,
    SliderCheckboxComponent,
    SourceRendererComponent,
    SourceTypeRendererComponent,
    StepProgressBarComponent,
    TemplateRendererComponent,
    TruncatedNameComponent,
    TypeAheadEditorComponent,
    UserRendererComponent,
  ],
})
export class SharedModule {}
