import { Route } from '@angular/router';

import { AccountingComponent } from '../accounting/accounting.component';
import { MatchingRuleSetsComponent } from '../accounting/chart/matching-rule-sets/matching-rule-sets.component';
import { DatasetBalancesComponent } from '../accounting/datasets/dataset-balances/dataset-balances.component';
import { DatasetJournalsComponent } from '../accounting/datasets/dataset-journals/dataset-journals.component';
import { DatasetLedgerComponent } from '../accounting/datasets/dataset-ledger/dataset-ledger.component';
import { DatasetLivestockComponent } from '../accounting/datasets/dataset-livestock/dataset-livestock.component';
import { DatasetRootComponent } from '../accounting/datasets/dataset-root/dataset-root.component';
import { DatasetTaxReportComponent } from '../accounting/datasets/dataset-tax-report/dataset-tax-report.component';
import { DatasetComponent } from '../accounting/datasets/dataset/dataset.component';
import { DatasetsComponent } from '../accounting/datasets/datasets/datasets.component';
import { ESignatureRequestHistoryComponent } from '../accounting/eSignature/esignature-request-history/esignature-request-history.component';
import { ESignatureRootComponent } from '../accounting/eSignature/esignature.component';
import { FileFeedsRootComponent } from '../accounting/file-feeds/file-feeds-root/file-feeds-root.component';
import { FileFeedsComponent } from '../accounting/file-feeds/file-feeds/file-feeds.component';
import { AccountsRootComponent } from '../accounting/ledger/accounts/accounts-root/accounts-root.component';
import { AccountsComponent } from '../accounting/ledger/accounts/accounts/accounts.component';
import { SourceAccountsTableComponent } from '../accounting/ledger/accounts/source-accounts-table/source-accounts-table.component';
import { JournalComponent } from '../accounting/ledger/journals/journal/journal.component';
import { LivestockComponent } from '../accounting/ledger/livestock/livestock.component';
import { TaxCodesComponent } from '../accounting/ledger/tax-codes/tax-codes.component';
import { TaxFormComponent } from '../accounting/ledger/taxForms/tax-form/tax-form.component';
import {
  ReportDocumentsComponent,
  ReportTemplateRootComponent,
} from '../accounting/reports';
import { FilePoliciesComponent } from '../accounting/reports/notes/policies/file-policies/file-policies.component';
import { FilePolicyComponent } from '../accounting/reports/notes/policies/file-policy/file-policy.component';
import { PolicyComponent } from '../accounting/reports/notes/policies/policy/policy.component';
import { ReportTemplateComponent } from '../accounting/reports/reportTemplates/report-template/report-template.component';
import { ReportTemplatesComponent } from '../accounting/reports/reportTemplates/report-templates/report-templates.component';
import { DivisionsComponent } from '../accounting/setup/divisions/divisions/divisions.component';
import { MatchingRulesComponent } from '../accounting/setup/matchingRules/matching-rules/matching-rules.component';
import { TradingAccountsComponent } from '../accounting/setup/tradingAccounts/trading-accounts/trading-accounts.component';
import { SourcesComponent } from '../accounting/sourcedata/sources/sources/sources.component';
import { WorkpapersComponent } from '../accounting/workpapers/workpapers/workpapers.component';
import {
  BankConnectionPageComponent,
  FeedDetailComponent,
  ReportPageComponent,
  RulePageComponent,
  TransactionPageComponent,
} from '../baco/components';
import { confirmDeactivateGuard } from '../core/guards/confirm-deactivate.guard';
import { LedgerAuthGuard } from '../core/guards/ledger-auth.guard';
import { DatasetTaxFormsComponent } from './../accounting/datasets/dataset-tax-forms/dataset-tax-forms.component';
import { DatasetTrialBalancesComponent } from './../accounting/datasets/dataset-trial-balances/dataset-trial-balances.component';
import { DistributionComponent } from './../accounting/ledger/auto-journals/distribution/distribution.component';
import { DisclosureTemplateComponent } from './../accounting/reports/notes/disclosures/disclosure-template/disclosure-template.component';
import { FileDisclosureTemplateComponent } from './../accounting/reports/notes/disclosures/file-disclosure-template/file-disclosure-template.component';
import { FileDisclosureTemplatesComponent } from './../accounting/reports/notes/disclosures/file-disclosure-templates/file-disclosure-templates.component';
import { assetsRootRoute } from './accounting-assets-routing';

const journalsRoute = {
  path: 'journals',
  component: DatasetJournalsComponent,
  canActivateChild: [LedgerAuthGuard],
  children: [{ path: ':id', component: JournalComponent }],
};

const datasetBalancesRoute: Route = {
  path: 'balances',
  component: DatasetBalancesComponent,
  children: [
    {
      path: '',
      redirectTo: 'trial-balances',
      pathMatch: 'full',
    },
    { path: 'trial-balances', component: DatasetTrialBalancesComponent },
    { path: 'ledger', component: DatasetLedgerComponent },
    { path: 'tax-report', component: DatasetTaxReportComponent },
    {
      path: 'tax-forms',
      component: DatasetTaxFormsComponent,
      children: [
        {
          path: ':id',
          component: TaxFormComponent,
        },
      ],
    },
  ],
};

const distributionsRoute: Route = {
  path: 'distributions',
  component: DistributionComponent,
};

const livestockRoute = {
  path: 'livestock',
  component: DatasetLivestockComponent,
  canActivateChild: [LedgerAuthGuard],
  children: [
    {
      path: ':tradingAccountId',
      component: LivestockComponent,
      canDeactivate: [confirmDeactivateGuard],
    },
  ],
};

const datasetRoute = {
  path: ':id',
  component: DatasetComponent,
  canActivateChild: [LedgerAuthGuard],
  children: [
    datasetBalancesRoute,
    journalsRoute,
    distributionsRoute,
    livestockRoute,
  ],
};

const defaultRoute: Route = {
  path: '',
  pathMatch: 'full',
  redirectTo: 'datasets',
};

const datasetsRoute = {
  path: 'datasets',
  component: DatasetRootComponent,
  canActivateChild: [LedgerAuthGuard],
  children: [{ path: '', component: DatasetsComponent }, datasetRoute],
  data: { title: 'Datasets' },
};

const accountsRoute: Route = {
  path: 'accounts',
  component: AccountsRootComponent,
  children: [
    { path: '', redirectTo: 'accounts-allocation', pathMatch: 'full' },
    {
      path: 'accounts-allocation',
      component: AccountsComponent,
      data: { title: 'Accounts' },
    },
    {
      path: 'source-accounts',
      component: SourceAccountsTableComponent,
      data: { title: 'Source Accounts' },
    },
  ],
};

const feedDetailsRoute: Route = {
  path: ':feedId',
  component: FeedDetailComponent,
  children: [
    {
      path: '',
      pathMatch: 'full',
      redirectTo: 'bank-connections',
    },
    {
      path: 'transactions',
      component: TransactionPageComponent,
    },
    {
      path: 'reports/:report-key',
      component: ReportPageComponent,
    },
    {
      path: 'rules',
      component: RulePageComponent,
    },
    {
      path: 'bank-connections',
      component: BankConnectionPageComponent,
    },
  ],
};

const bankFeedsRoute: Route = {
  path: 'feeds',
  component: FileFeedsRootComponent,
  data: { title: 'Bank Feeds' },
  children: [{ path: '', component: FileFeedsComponent }, feedDetailsRoute],
};

const reportsRoute: Route = {
  path: 'reports',
  component: ReportTemplateRootComponent,
  children: [
    { path: '', redirectTo: 'templates', pathMatch: 'full' },
    {
      path: 'templates',
      component: ReportTemplatesComponent,
      data: { title: 'Report Templates' },
    },
    {
      path: 'templates/:id',
      component: ReportTemplateComponent,
      data: { title: 'Report Template' },
    },
    {
      path: 'documents',
      component: ReportDocumentsComponent,
      data: { title: 'Documents' },
    },
    {
      path: 'documents/:id',
      component: ReportDocumentsComponent,
      data: { title: 'Documents' },
    },
  ],
};

const eSignRoute = {
  path: 'e-signature',
  component: ESignatureRootComponent,
  canActivateChild: [LedgerAuthGuard],
  children: [
    { path: 'request-histories', component: ESignatureRequestHistoryComponent },
  ],
  data: { title: 'E-Signature' },
};

const sourcesRoute = {
  path: 'sources',
  component: SourcesComponent,
  data: { title: 'Sources' },
};

const divisionsRoute = {
  path: 'divisions',
  component: DivisionsComponent,
  data: { title: 'Divisions' },
};

const tradingAccountsRoute = {
  path: 'tradingAccounts',
  component: TradingAccountsComponent,
  data: { title: 'Trading Accounts' },
};

const accountingPoliciesRoute = {
  path: 'accountingPolicies',
  component: FilePoliciesComponent,
  data: { title: 'Accounting Policies' },
};

const filePolicyRoute = {
  path: 'accountingPolicies/file',
  component: FilePolicyComponent,
  data: { title: 'Accounting Policies' },
  children: [
    {
      path: ':id',
      component: PolicyComponent,
      data: { title: 'Accounting Policy' },
    },
  ],
};

const disclosureTemplatesRoute = {
  path: 'disclosureTemplates',
  component: FileDisclosureTemplatesComponent,
  data: { title: 'Disclosure Templates' },
};

const fileDisclosureTemplateRoute = {
  path: 'disclosureTemplates/file',
  component: FileDisclosureTemplateComponent,
  data: { title: 'Disclosure Templates' },
  children: [
    {
      path: ':id',
      component: DisclosureTemplateComponent,
      data: { title: 'Disclosure Template' },
    },
  ],
};

const matchingRuleSetsRoute = {
  path: 'matching-rule-sets',
  component: MatchingRuleSetsComponent,
  data: { title: 'Matching Rule Sets' },
};

const matchingRulesRoute = {
  path: 'matching-rule-sets/:id/matching-rules',
  component: MatchingRulesComponent,
  data: { title: 'Matching Rules' },
};

const taxCodesRoute = {
  path: 'tax-codes',
  component: TaxCodesComponent,
  data: { title: 'Tax Codes' },
};

const workpapersRoute = {
  path: 'workpapers',
  component: WorkpapersComponent,
  data: { title: 'Workpapers' },
};

const accountingChildRoutes = [
  accountingPoliciesRoute,
  accountsRoute,
  assetsRootRoute,
  bankFeedsRoute,
  datasetsRoute,
  defaultRoute,
  disclosureTemplatesRoute,
  divisionsRoute,
  eSignRoute,
  fileDisclosureTemplateRoute,
  filePolicyRoute,
  matchingRuleSetsRoute,
  matchingRulesRoute,
  reportsRoute,
  sourcesRoute,
  taxCodesRoute,
  tradingAccountsRoute,
  workpapersRoute,
];

export const accountingRoute = {
  path: 'accounting/:id',
  component: AccountingComponent,
  canActivate: [LedgerAuthGuard],
  canActivateChild: [LedgerAuthGuard],
  children: accountingChildRoutes,
};
export const accountingDefaultFileRoute = {
  path: 'accounting/:entityId/default',
  component: AccountingComponent,
  canActivate: [LedgerAuthGuard],
  canActivateChild: [LedgerAuthGuard],
  children: accountingChildRoutes,
};
