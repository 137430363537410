<crs-form-error [error]="error" />

<!-- Display Options -->
<form novalidate [formGroup]="form" (validSubmit)="getReport()">
  <div class="row">
    <div class="col-sm-6">
      <div class="form-group">
        <label class="control-label">Display</label>
        <ng-select
          bindLabel="name"
          bindValue="value"
          formControlName="journalCategory"
          [clearable]="false"
          [items]="journalCategories | enumToArray"
        />
      </div>

      <div class="form-group">
        <label class="control-label">Account Type</label>
        <ng-select
          bindLabel="name"
          bindValue="value"
          formControlName="groupByAccountType"
          [clearable]="false"
          [items]="groupByAccountTypes | enumToArray"
        />
      </div>

      <div class="form-group" *ngIf="isGroupDataset">
        <label class="control-label">
          <i aria-hidden="true" class="fas fa-copy text-primary"></i>
          Consolidation Mode
        </label>
        <ng-select
          bindLabel="name"
          bindValue="value"
          formControlName="consolidationMode"
          [clearable]="false"
          [items]="consolidationModes | enumToArray"
        />
      </div>
    </div>
    <div class="col-sm-6">
      <div class="form-group">
        <label class="control-label">Start Date</label>
        <crs-date-picker formControlName="startDate" />
      </div>

      <div class="form-group">
        <label class="control-label">End Date</label>
        <crs-date-picker formControlName="endDate" />
      </div>
    </div>
  </div>

  <hr />

  <div class="row">
    <div class="col-sm-6">
      <div class="form-group">
        <label class="control-label">Comparative Dataset</label>
        <crs-dataset-select
          formControlName="comparativeDataset"
          [datasets]="comparativeDatasets"
          [fileId]="fileId"
          [placeholder]="'No comparative'"
        />
      </div>
    </div>
  </div>

  <!-- Buttons -->
  <div class="btn-menu">
    <button class="btn btn-primary" type="submit" [promiseBtn]="busy.update">
      Run Report
    </button>

    <div class="d-inline-block" *ngIf="showExportButton">
      <button
        class="btn btn-secondary"
        type="button"
        *ngIf="!canAutoExport"
        [promiseBtn]="busy.exportJournal"
        (click)="export(false)"
      >
        Export as Balancing Journal
      </button>

      <div class="btn-group" *ngIf="canAutoExport">
        <button
          class="btn btn-secondary"
          type="submit"
          [promiseBtn]="busy.exportJournal"
          (click)="export(true)"
        >
          Export as Balancing Journal
        </button>
        <button
          aria-expanded="false"
          aria-haspopup="true"
          class="btn btn-secondary dropdown-toggle dropdown-toggle-split"
          data-toggle="dropdown"
          type="button"
        >
          <span class="sr-only">Export Options</span>
        </button>
        <div class="dropdown-menu">
          <button class="dropdown-item" type="submit" (click)="export(true)">
            Automatically Export Journal <small>(Default)</small>
          </button>
          <button class="dropdown-item" type="submit" (click)="export(false)">
            Mark as Exported <small>(Requires manually entering journal)</small>
          </button>
        </div>
      </div>

      <div class="d-inline-block ml-2">
        <a
          class="btn-sm fas-btn-primary-ghost"
          placement="right"
          popoverTitle="Balancing Journals"
          [ngbPopover]="balancingJournal"
          [routerLink]="[]"
        >
          <i aria-hidden="true" class="fas fa-info-circle"></i>
        </a>
      </div>

      <ng-template #balancingJournal>
        <p>
          Exporting a balancing journal will 'tag' all transactions as exported,
          and store the net balancing journal in the system.
        </p>
        <p>
          It is advisable to do a fresh import from the source system after the
          balancing journal has been processed, because these 'tagged' journals
          will now be excluded when calculating a trial balance.
        </p>
      </ng-template>
    </div>
  </div>
</form>

<div class="spacer-lg"></div>

<!-- Search -->
<div class="row">
  <div class="col-sm-7">
    <div class="icon-input-wrapper flex-grow-1 mr-2">
      <i aria-hidden="true" class="fas fa-search input-icon"></i>
      <input
        aria-describedby="searchIcon"
        placeholder="Search..."
        type="search"
        [formControl]="search"
      />
    </div>
  </div>
</div>

<!-- Grid -->
<div>
  <ag-grid-angular
    #agGrid
    class="ag-theme-material"
    style="width: 100%"
    [gridOptions]="gridOptions"
    [rowData]="trialBalance"
  />
</div>
