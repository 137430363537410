import { AccountComponent } from './ledger/accounts/account/account.component';
import { AccountHeaderComponent } from './ledger/accounts/account-header/account-header.component';
import { AccountingComponent } from './accounting.component';
import { AccountingCoreModule } from './core/accounting-core.module';
import { AccountingService } from './accounting.service';
import { AccountsComponent } from './ledger/accounts/accounts/accounts.component';
import { AccountsMenuComponent } from './ledger/accounts/accounts-menu/accounts-menu.component';
import { AccountsRootComponent } from './ledger/accounts/accounts-root/accounts-root.component';
import { ActiveFileService } from './active-file.service';
import { AdminAccountsComponent } from './chart/headers-and-accounts/admin-accounts/admin-accounts.component';
import { ApiAuthorisationComponent } from './connections/api-authorisation/api-authorisation.component';
import { ApiAuthorisationModalComponent } from './connections/api-authorisation-modal/api-authorisation-modal.component';
import { AssetsModule } from './assets/assets.module';
import { BacoModule } from '../baco/baco.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { DatasetBalancesComponent } from './datasets/dataset-balances/dataset-balances.component';
import { DatasetComponent } from './datasets/dataset/dataset.component';
import { DatasetJournalsComponent } from './datasets/dataset-journals/dataset-journals.component';
import { DatasetLedgerComponent } from './datasets/dataset-ledger/dataset-ledger.component';
import { DatasetLivestockComponent } from './datasets/dataset-livestock/dataset-livestock.component';
import { DatasetRootComponent } from './datasets/dataset-root/dataset-root.component';
import { DatasetsComponent } from './datasets/datasets/datasets.component';
import { DatasetSelectModalComponent } from './datasets/dataset-select-modal/dataset-select-modal.component';
import { DatasetTaxFormsComponent } from './datasets/dataset-tax-forms/dataset-tax-forms.component';
import { DatasetTaxReportComponent } from './datasets/dataset-tax-report/dataset-tax-report.component';
import { DatasetTrialBalancesComponent } from './datasets/dataset-trial-balances/dataset-trial-balances.component';
import { DistributionComponent } from './ledger/auto-journals/distribution/distribution.component';
import { DivisionComponent } from './setup/divisions/division/division.component';
import { DivisionsComponent } from './setup/divisions/divisions/divisions.component';
import { ESignatureModule } from './eSignature/esignature.module';
import { FileComponent } from './files/file/file.component';
import { FileDeleteComponent } from './files/file-delete/file-delete.component';
import { FileFeedsActivateChartOfAccountsComponent } from './file-feeds/file-feeds-activate-chart-of-accounts/file-feeds-activate-chart-of-accounts.component';
import { FileFeedsComponent } from './file-feeds/file-feeds/file-feeds.component';
import { FileFeedsFormComponent } from './file-feeds/file-feeds-form/file-feeds-form.component';
import { FileFeedsRootComponent } from './file-feeds/file-feeds-root/file-feeds-root.component';
import { FileSelectComponent } from './files/file-select/file-select.component';
import { FirmModule } from './../firm/firm.module';
import { HeadersAccountsAndTaxCodesComponent } from './admin/headers-accounts-and-tax-codes/headers-accounts-and-tax-codes.component';
import { HeadersAndAccountsComponent } from './chart/headers-and-accounts/headers-and-accounts.component';
import { JournalComponent } from './ledger/journals/journal/journal.component';
import { LedgerReportComponent } from './ledger/ledger-report/ledger-report.component';
import { LedgerReportModalComponent } from './ledger/ledger-report-modal/ledger-report-modal.component';
import { LivestockComponent } from './ledger/livestock/livestock.component';
import { MasterMatchingRulesComponent } from './chart/matching-rule-sets/master-matching-rules/master-matching-rules.component';
import { MatchingRuleComponent } from './setup/matchingRules/matching-rule/matching-rule.component';
import { MatchingRulesComponent } from './setup/matchingRules/matching-rules/matching-rules.component';
import { MatchingRuleSetsComponent } from './chart/matching-rule-sets/matching-rule-sets.component';
import { MigrationsModule } from './migrations/migrations.module';
import { NgModule } from '@angular/core';
import { ReportsModule } from './reports/reports.module';
import { SelectCoaComponent } from './chart/headers-and-accounts/admin-accounts/select-coa/select-coa.component';
import { SharedModule } from '../shared/shared.module';
import { SourceAccountFormComponent } from './ledger/accounts/source-account-form/source-account-form.component';
import { SourceAccountSelectComponent } from './ledger/sourceAccounts/source-account-select/source-account-select.component';
import { SourceAccountsTableComponent } from './ledger/accounts/source-accounts-table/source-accounts-table.component';
import { SourceComponent } from './sourcedata/sources/source/source.component';
import { SourceDivisionCreateComponent } from './sourcedata/sourceDivisions/source-division-create/source-division-create.component';
import { SourcesComponent } from './sourcedata/sources/sources/sources.component';
import { SourceSelectComponent } from './sourcedata/sources/source-select/source-select.component';
import { StandardAccountFormComponent } from './chart/headers-and-accounts/standard-account-form/standard-account-form.component';
import { StandardAccountHeadersGridComponent } from './chart/headers-and-accounts/standard-account-headers-grid/standard-account-headers-grid.component';
import { StandardAccountsHeaderFormComponent } from './chart/headers-and-accounts/standard-accounts-header-form/standard-accounts-header-form.component';
import { StandardAccountsTableComponent } from './chart/headers-and-accounts/standard-accounts-table/standard-accounts-table.component';
import { StandardTaxCodeFormComponent } from './chart/standard-tax-codes/standard-tax-code-form/standard-tax-code-form.component';
import { StandardTaxCodesComponent } from './chart/standard-tax-codes/standard-tax-codes.component';
import { StandardTaxCodesTableComponent } from './chart/standard-tax-codes/standard-tax-codes-table/standard-tax-codes-table.component';
import { TaxCodeFormComponent } from './ledger/tax-codes/tax-code-form/tax-code-form.component';
import { TaxCodesComponent } from './ledger/tax-codes/tax-codes.component';
import { TaxCodesTableComponent } from './ledger/tax-codes/tax-codes-table/tax-codes-table.component';
import { TaxFormComponent } from './ledger/taxForms/tax-form/tax-form.component';
import { TradingAccountComponent } from './setup/tradingAccounts/trading-account/trading-account.component';
import { TradingAccountsComponent } from './setup/tradingAccounts/trading-accounts/trading-accounts.component';
import { WorkpapersComponent } from './workpapers/workpapers/workpapers.component';

@NgModule({
  imports: [
    AccountingCoreModule,
    AssetsModule,
    BacoModule,
    BrowserAnimationsModule,
    CommonModule,
    ESignatureModule,
    FirmModule,
    MigrationsModule,
    ReportsModule,
    SharedModule,
  ],
  declarations: [
    AccountComponent,
    AccountHeaderComponent,
    AccountingComponent,
    AccountsComponent,
    AccountsMenuComponent,
    AccountsRootComponent,
    AdminAccountsComponent,
    ApiAuthorisationComponent,
    ApiAuthorisationModalComponent,
    DatasetBalancesComponent,
    DatasetComponent,
    DatasetJournalsComponent,
    DatasetLedgerComponent,
    DatasetLivestockComponent,
    DatasetRootComponent,
    DatasetsComponent,
    DatasetSelectModalComponent,
    DatasetTaxFormsComponent,
    DatasetTaxReportComponent,
    DatasetTrialBalancesComponent,
    DistributionComponent,
    DivisionComponent,
    DivisionsComponent,
    FileComponent,
    FileDeleteComponent,
    FileFeedsActivateChartOfAccountsComponent,
    FileFeedsComponent,
    FileFeedsFormComponent,
    FileFeedsRootComponent,
    FileSelectComponent,
    HeadersAccountsAndTaxCodesComponent,
    HeadersAndAccountsComponent,
    JournalComponent,
    LedgerReportComponent,
    LedgerReportModalComponent,
    LivestockComponent,
    MasterMatchingRulesComponent,
    MatchingRuleComponent,
    MatchingRulesComponent,
    MatchingRuleSetsComponent,
    SelectCoaComponent,
    SourceAccountFormComponent,
    SourceAccountSelectComponent,
    SourceAccountsTableComponent,
    SourceComponent,
    SourceDivisionCreateComponent,
    SourcesComponent,
    SourceSelectComponent,
    StandardAccountFormComponent,
    StandardAccountHeadersGridComponent,
    StandardAccountsHeaderFormComponent,
    StandardAccountsTableComponent,
    StandardTaxCodeFormComponent,
    StandardTaxCodesComponent,
    StandardTaxCodesTableComponent,
    TaxCodeFormComponent,
    TaxCodesComponent,
    TaxCodesTableComponent,
    TaxFormComponent,
    TradingAccountComponent,
    TradingAccountsComponent,
    WorkpapersComponent,
  ],
  providers: [AccountingService, ActiveFileService],
})
export class AccountingModule {}
