import { WorkpaperStarter } from './workpaper-starter';
import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class WorkpaperService {

  readonly base = 'api/accounting/workpapers';

  constructor(private readonly _apiService: ApiService) { }

  getStarters(): Observable<WorkpaperStarter[]> {
    return this._apiService.get<WorkpaperStarter[]>(this.base + '/starters').pipe(map(r => r.map(s => new WorkpaperStarter(s))));
  }

  getStarter(id: string, fileId: string): Observable<string> {
    return this._apiService.get<any>(this.base + '/starters/' + id + '/download?fileId=' + fileId).pipe(map(r => r.data));
  }
}
