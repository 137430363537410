import { DatePipe } from '@angular/common';
import { Component, OnInit, Input, Inject } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EMPTY, Observable, Subject, Subscription } from 'rxjs';
import { tap, exhaustMap, finalize, catchError } from 'rxjs/operators';
import { ActiveFileService } from 'src/app/accounting';
import {
  ReportOutput,
  ReportPageOutput,
} from 'src/app/accounting/reports/reportViewer/report-output';
import {
  CellInput,
  ReportOutputBuilder,
} from 'src/app/accounting/reports/reportViewer/report-output-builder';
import { ReportDownloadService } from 'src/app/accounting/reports/services';
import { BACO_DATE_FORMAT } from 'src/app/baco/baco.tokens';
import { RuleMovementType } from 'src/app/baco/enums';
import {
  BacoConnectionValidationDto,
  BacoTransactionValidationDto,
} from 'src/app/baco/interfaces';
import { BankConnectionSettingsService } from 'src/app/baco/services';
import { DownloadService, MessageService } from 'src/app/core';

@Component({
  selector: 'app-bank-connection-transaction-validation',
  templateUrl: './bank-connection-transaction-validation.component.html',
  styleUrls: ['./bank-connection-transaction-validation.component.scss'],
})
export class BankConnectionTransactionValidationComponent implements OnInit {
  @Input() id: string;
  @Input() params: {
    validationData: BacoConnectionValidationDto;
    bankConnectionName: string;
  };

  masterId: number;
  fileId: string;

  transactionList: BacoTransactionValidationDto[] = [];
  numberOfMismatchedTransactions: number = 0;

  generateButtonStream = new Subject();
  form: UntypedFormGroup;

  subscriptions: Subscription[] = [];
  error: string | any[] = null;
  busy = {
    load: false,
    submit: null,
    generate: null,
  };

  report: ReportOutput;

  constructor(
    public activeModal: NgbActiveModal,
    private readonly _bankConnectionSettingsService: BankConnectionSettingsService,
    private messageService: MessageService,
    private readonly _datePipe: DatePipe,
    private readonly reportDownloadService: ReportDownloadService,
    private readonly reportOutputBuilder: ReportOutputBuilder,
    private downloadService: DownloadService,
    private activeFileService: ActiveFileService,
    private route: ActivatedRoute,
    @Inject(BACO_DATE_FORMAT) private dateFormat: string
  ) {}

  ngOnInit(): void {
    this.transactionList = this.params.validationData?.transactionList;
    this.numberOfMismatchedTransactions =
      this.params.validationData?.numberOfMismatchedTransactions;
    this.route.params.subscribe((params) => {
      this.masterId = parseInt(params.masterId, 10);
      this.fileId = this.activeFileService.file.id;
    });

    this.subscriptions.push(
      this.generateButtonStream
        .pipe(
          tap(() => (this.error = null)),
          exhaustMap(() => this.downloadExcel$())
        )
        .subscribe()
    );
  }

  review() {
    this.generateButtonStream.next();
  }

  onClickGenerate() {
    this.generateButtonStream.next();
  }

  delete() {
    const mismatchedTransactionIds = this.transactionList
      .filter((transaction) => transaction.mismatched)
      .map((transaction) => transaction.id);

    this._bankConnectionSettingsService
      .deleteMismatchedTransactions(this.id, mismatchedTransactionIds)
      .subscribe(
        (result) => {
          if (result) {
            this.messageService.success(
              'Mismatched transactions deleted successfully.'
            );
            this.activeModal.close();
          } else {
            this.messageService.error(
              'Failed to delete mismatched transactions. Please try again.'
            );
          }
        },
        (error) => {
          console.error('Error deleting mismatched transactions: ', error);
          this.messageService.error(
            `Error deleting mismatched transactions: ${error?.messageString}`
          );
        }
      );
  }

  cancel() {
    this.activeModal.dismiss();
  }

  downloadExcel$(): Observable<string> {
    const loading$ = new Subject();
    this.busy.generate = loading$.subscribe();

    const reportPages: ReportPageOutput[] = this.generateExcelReportPages();

    this.report = this.reportOutputBuilder.createReportOutput({
      fileId: this.fileId,
      pages: reportPages,
      dateFormat: this.dateFormat,
    });

    return this.reportDownloadService
      .exportAsExcel$(
        this.report,
        `TRANSACTION VALIDATION REPORT - ${this.params.bankConnectionName}`
      )
      .pipe(
        finalize(() => {
          loading$.complete();
        }),
        tap((url) => this.downloadService.download(url)),
        catchError((err) => {
          this.messageService.error(err);
          this.showError(err);
          return EMPTY;
        })
      );
  }

  private groupTransactionsByAccount(
    transactions: BacoTransactionValidationDto[]
  ): { [key: string]: BacoTransactionValidationDto[] } {
    return transactions.reduce((acc, transaction) => {
      const accountId = transaction.bacoBankAccountId || 'unknown';
      if (!acc[accountId]) {
        acc[accountId] = [];
      }
      acc[accountId].push(transaction);
      return acc;
    }, {});
  }

  private generateExcelReportPages(): ReportPageOutput[] {
    const transactionsByAccount = this.groupTransactionsByAccount(
      this.transactionList
    );

    return Object.keys(transactionsByAccount).map((accountId) => {
      const bacoBankTransactionAccount = transactionsByAccount[accountId][0];

      const accountTransactions = transactionsByAccount[accountId];
      const sheetTitle = `${
        bacoBankTransactionAccount.bacoBankAccountNumber
          ? `(${bacoBankTransactionAccount.bacoBankAccountNumber})`
          : ''
      } ${bacoBankTransactionAccount.bacoBankAccountName}`;
      const headerTitle = bacoBankTransactionAccount.bacoBankAccountNumber
        ? `ACCOUNT NUMBER: ${bacoBankTransactionAccount.bacoBankAccountNumber} | ACCOUNT NAME: ${bacoBankTransactionAccount.bacoBankAccountName}`
        : `ACCOUNT NAME: ${bacoBankTransactionAccount.bacoBankAccountName}`;

      const columns = [
        { title: 'Date', width: 100 },
        { title: 'Type', width: 120 },
        { title: 'Reference', width: 120 },
        { title: 'Description', width: 300, align: 0 },
        { title: 'Money In', width: 120 },
        { title: 'Money Out', width: 120 },
        { title: 'Locked', width: 100 },
        { title: 'Mismatched', width: 100 },
      ];

      const headerRowCells = this.reportOutputBuilder.createHeaderRow(
        columns.map((col) => ({ value: col.title }))
      );

      const rowCellData: CellInput[][] = accountTransactions.map(
        (transaction) => [
          {
            value: this._datePipe.transform(
              transaction.transactionLocalDate,
              this.dateFormat
            ),
          },
          { value: transaction.type },
          { value: transaction.reference },
          {
            value: transaction.description || transaction.originalDescription,
            alignment: 0,
          },
          {
            value:
              transaction.movementType === RuleMovementType.MoneyIn
                ? transaction.amount
                : '',
            valueType: 1,
          },
          {
            value:
              transaction.movementType === RuleMovementType.MoneyOut
                ? transaction.amount
                : '',
            valueType: 1,
          },
          { value: transaction.locked ? 'Yes' : 'No' },
          { value: transaction.mismatched ? 'Yes' : 'No' },
        ]
      );

      const rowData = rowCellData.map((row) =>
        this.reportOutputBuilder.createDataRow(row)
      );

      const rows = [headerRowCells, ...rowData];

      return {
        title: sheetTitle,
        orientation: 1,
        hasHeaderOrFooter: true,
        pageContent: {
          orientation: 1,
          header: [
            {
              alignment: 0,
              elementType: 0,
              isNew: false,
              level: '0',
              marginBottom: false,
              marginTop: false,
              style: 0,
              text: headerTitle,
            },
            {
              elementType: 7,
              height: '10',
              isNew: false,
            },
          ],
          content: [
            {
              elementType: 10, // Table
              columns: this.reportOutputBuilder.createPageColumns(columns),
              rows,
            },
          ],
        },
        warnings: [],
        pageTypeMasterId: null,
      };
    });
  }

  private showError(err) {
    this.error = err;
    this.messageService.error(err);
  }
}
