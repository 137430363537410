
import { Component, OnInit, OnDestroy } from '@angular/core';
import { of, Subscription } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';

import { getDefaultGridOptions, ProgressBarService } from 'src/app/shared';
import { DownloadService } from './../../../core/services/download.service';
import { WorkpaperService } from '../workpaper.service';
import { ActivatedRoute } from '@angular/router';
import { WorkpaperStarter } from '../workpaper-starter';
import { ApiError } from 'src/app/core/interceptors/api-error';

@Component({
  selector: 'crs-workpapers',
  templateUrl: './workpapers.component.html',
  styleUrls: ['./workpapers.component.scss']
})
export class WorkpapersComponent implements OnInit, OnDestroy {
  fileId: string;
  collapsed: boolean;
  error: string;

  busy = {
    load: null
  };

  starters: WorkpaperStarter[];
  fileSubscription: Subscription;
  notSetup = false;

  gridOptions = getDefaultGridOptions();

  constructor(
    private workpaperService: WorkpaperService,
    private downloadService: DownloadService,
    private route: ActivatedRoute,
    private progressBar: ProgressBarService) { }

  ngOnInit() {

    this.fileSubscription = this.route.params.subscribe(() => {
      this.fileId = this.route.snapshot.parent.paramMap.get('id');
      this.getStarters();
    });
  }

  ngOnDestroy() {
    this.fileSubscription.unsubscribe();
  }

  getStarters() {
    this.error = null;
    this.busy.load = this.workpaperService.getStarters().pipe(
      catchError(err => {
        if (err instanceof ApiError) {
          if (err.messageString.includes('not setup')) {
            this.notSetup = true;
            return of([]);
          }
        }
        this.showError(err);
        return of([]);
      })).subscribe(starters => this.starters = starters);
  }

  showError(error) {
    this.error = error;
  }

  selectStarter(param) {

    this.error = null;

    const progressBar = this.progressBar.ref();
    progressBar.start();

    this.workpaperService.getStarter(param.data.id, this.fileId)
      .pipe(finalize(() => {
        progressBar.complete();
      }))
      .subscribe(
        url => this.downloadService.download(url),
        err => this.showError(err)
      );
  }
}
