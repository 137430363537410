import {
  ReportTemplatePage,
  ReportTemplateFinancialPageDetail,
  ReportTemplateNotePageDetail,
  ReportTemplateDepreciationPageDetail,
  ReportTemplateCashflowPageDetail,
} from './reportTemplatePage';
import { DepreciationType } from './../../assets/assets/depreciation-type';
import { Injectable } from '@angular/core';
import {
  UntypedFormBuilder,
  Validators,
  UntypedFormArray,
} from '@angular/forms';

import { ReportTemplate } from './reportTemplate';
import {
  ReportTemplateColumn,
  ColumnType,
  ColumnCalculationType,
  ConsolidationMode,
} from '.';
import { ReportPageType } from '../reportPages';
import { DepreciationReportType } from '../../assets/asset-reports/depreciation-report-type';
import { DepreciationPool } from '../../assets/assets/depreciation-pool';
import { ReportTemplateDataService } from './report-template-data.service';
import { ProfitDisplayOption } from '../enums/profit-display-option';
import { ProfitLossLayoutOption } from '../enums/profit-loss-layout-option';
import { cashflowReportModeOptions } from './report-template-page/cashflow-report-mode-options';

@Injectable({
  providedIn: 'root',
})
export class ReportTemplateFormBuilder {
  constructor(
    private readonly _formBuilder: UntypedFormBuilder,
    private readonly _reportTemplateDataService: ReportTemplateDataService
  ) {}

  buildForm(template: ReportTemplate = null) {
    const form = this._formBuilder.group({
      id: [''],
      name: ['New Report', [Validators.required, Validators.maxLength(256)]],
      title: ['Report', [Validators.required, Validators.maxLength(256)]],
      useDefaultDates: [true, Validators.required],
      startDate: [null],
      endDate: [null],
      defaultFooterId: [null, Validators.required],
      entity: [],
      isConsolidated: [false],
      entityType: [8, Validators.required],
      columns: this._formBuilder.array([]),
      pages: this._formBuilder.array([]),
      reportStyle: [null],
    });

    if (!template) return form;

    this.buildColumns(
      form.get('columns') as UntypedFormArray,
      template.columns
    );
    this.buildPages(form.get('pages') as UntypedFormArray, template.pages);

    form.patchValue(template);
    return form;
  }

  private buildColumns(
    columnsFormArray: UntypedFormArray,
    columns: ReportTemplateColumn[]
  ) {
    const count = columns ? columns.length : 0;
    do {
      columnsFormArray.push(this.initColumn());
    } while (columnsFormArray.length < count);
  }

  private buildPages(
    pagesFormArray: UntypedFormArray,
    pages: ReportTemplatePage[]
  ) {
    // Todo: optimise this so we change the initPage to only have those parts that it requires
    pages.forEach((p) => {
      if (p.financialDetail == null)
        p.financialDetail = new ReportTemplateFinancialPageDetail({});
      if (p.noteDetail == null)
        p.noteDetail = new ReportTemplateNotePageDetail({});
      if (p.depreciationDetail == null)
        p.depreciationDetail = new ReportTemplateDepreciationPageDetail({});
      if (p.cashflowDetail == null)
        p.cashflowDetail = new ReportTemplateCashflowPageDetail({});
      if (!p.financialDetail.customColumns)
        p.financialDetail.customColumns = [];
    });

    pages.forEach((p) => {
      const pageForm = this.initPage(null);
      pagesFormArray.push(pageForm);
      if (p.financialDetail) {
        this.buildColumns(
          pageForm
            .get('financialDetail')
            .get('customColumns') as UntypedFormArray,
          p.financialDetail.customColumns
        );
      }
    });
  }

  initColumn(type: ColumnType = ColumnType.Text) {
    const group = this._formBuilder.group({
      sortOrder: [1],
      columnType: type,
      dataset: [null, [Validators.required]],
      headerText: null,
      periodText: type === ColumnType.Number ? 'Variance' : null,
      signText: '$',
      spreadBy: null,
      startDate: null,
      endDate: null,
      division: null,
      tradingAccount: null,
      calculationType: ColumnCalculationType.Variance,
      consolidationMode: ConsolidationMode.None,
    });

    return group;
  }

  initPage(type: ReportPageType) {
    return this._formBuilder.group({
      id: null,
      pageType: type,
      customTitle: [null, { updateOn: 'blur' }],
      financialDetail: this._formBuilder.group({
        separateTradingAccounts: [false],
        profitDisplayOption: ProfitDisplayOption.ProfitAfterTax,
        profitlossLayoutOption: ProfitLossLayoutOption.Standard,
        customiseColumns: [false],
        customColumns: this._formBuilder.array([]),
        layout: null,
        layoutFull: null,
      }),
      noteDetail: this._formBuilder.group({
        reportingSuite: [
          this._reportTemplateDataService.defaultReportingSuite,
          Validators.required,
        ],
        auto: [true],
        policySelectors: null,
        policySelectorsFull: this._formBuilder.array([]),
        disclosures: null,
        disclosuresFull: this._formBuilder.array([]),
      }),
      depreciationDetail: this._formBuilder.group({
        year: [null, Validators.required],
        entityId: [null, Validators.required],
        reportType: [DepreciationReportType.FullSummary, Validators.required],
        assetGroups: [[]],
        pool: [DepreciationPool.GeneralPool],
        depreciationType: [DepreciationType.Taxation],
        showPoolSummary: [true],
      }),
      cashflowDetail: this._formBuilder.group({
        cashflowMode: cashflowReportModeOptions.Auto,
      }),
    });
  }
}
