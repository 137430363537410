<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item"><a [routerLink]="'/admin'">Admin Centre</a></li>
    <li class="breadcrumb-item"><a [routerLink]="['../']">Practices</a></li>
    <li aria-current="page" class="breadcrumb-item active">
      {{ isAdd ? 'New' : '' }} Practice
    </li>
  </ol>
</nav>

<form
  class="pad-x"
  novalidate
  [formGroup]="form"
  [ngBusy]="busy.load"
  (validSubmit)="submit()"
>
  <h2 class="form-group-title">{{ isAdd ? 'New' : '' }} Practice</h2>

  <hr />

  <div class="row">
    <div class="col-form-md">
      <div class="form-group required">
        <label class="control-label">Name</label>
        <input
          class="form-control"
          formControlName="name"
          placeholder="Sydney..."
          type="text"
        />
      </div>

      <div class="form-group required">
        <label class="control-label">Trading Name</label>
        <input
          class="form-control"
          formControlName="tradingName"
          placeholder="My Practice Sydney..."
          type="text"
        />
      </div>

      <div class="form-group">
        <label class="control-label">ABN</label>
        <input
          class="form-control"
          formControlName="abn"
          type="text"
          [crsBusinessNumber]="'abn'"
          [value]="form.get('abn').value | businessNumber : 'abn'"
        />
      </div>
    </div>
  </div>

  <crs-address-form
    columnClass="col-form-lg"
    [formGroup]="form.controls['address']"
  ></crs-address-form>

  <div class="form-inline">
    <h2 class="form-group-title">Auditor</h2>
    <div class="ml-3">
      <crs-slider-checkbox
        [formGroup]="form.controls['auditor']"
      ></crs-slider-checkbox>
    </div>
  </div>
  <hr />

  <div *ngIf="form.get('auditor.isAuditorStatusEnabled').value">
    <crs-auditor
      columnClass="col-form-lg required"
      [copyFromAddress]="form.controls['address'].value"
      [copyFromName]="form.controls['name'].value"
      [formGroup]="form.controls['auditor']"
    ></crs-auditor>
  </div>

  <div class="form-inline">
    <h2 class="form-group-title">Auditor SMSF</h2>
    <div class="ml-3">
      <crs-slider-checkbox
        [formGroup]="form.controls['auditorSMSF']"
      ></crs-slider-checkbox>
    </div>
  </div>
  <hr />

  <div *ngIf="form.get('auditorSMSF.isAuditorStatusEnabled').value">
    <crs-auditor
      columnClass="col-form-lg required"
      [auditorNumberRequired]="true"
      [copyFromAddress]="form.controls['address'].value"
      [copyFromName]="form.controls['name'].value"
      [formGroup]="form.controls['auditorSMSF']"
    ></crs-auditor>
  </div>

  <h2 class="form-group-title">Report Defaults</h2>
  <hr />
  <div class="row">
    <div class="col-form-sm">
      <div class="form-group">
        <label class="control-label">Brand Colour (Hex)</label>
        <div class="input-group">
          <input
            class="form-control"
            formControlName="brandColour"
            placeholder="#000000"
            type="text"
          />
          <div class="input-group-append" *ngIf="form.get('brandColour').value">
            <span
              class="input-group-text"
              style="width: 38px"
              [style.background-color]="form.get('brandColour').value"
            ></span>
          </div>
        </div>
        <!-- <p>
          <small>You can <a href="https://htmlcolorcodes.com/color-picker/" target="_blank" rel="noopener noreferrer">click here</a> for help finding a colour</small>
        </p> -->
      </div>

      <div class="form-group">
        <label class="control-label">Default Association State</label>
        <ng-select
          bindLabel="name"
          bindValue="value"
          formControlName="defaultAssociationStateId"
          [items]="associationStates | enumToArray"
        ></ng-select>
      </div>

      <div class="form-group">
        <label class="control-label"
          >Default Report Format <small>(when saving a report)</small></label
        >
        <ng-select
          bindLabel="name"
          bindValue="value"
          formControlName="defaultReportSaveFormat"
          [clearable]="false"
          [items]="reportFormats | enumToArray"
        ></ng-select>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <h2 class="form-group-title">Practice Users</h2>

      <hr />

      <div class="btn-toolbar" role="toolbar">
        <div class="btn-menu">
          <button
            class="btn btn-secondary"
            type="button"
            (click)="addOfficeUser()"
          >
            <i aria-hidden="true" class="fas fa-plus inset"></i>
            Add User
          </button>
        </div>

        <div class="form-group ml-2 w-50">
          <div class="icon-input-wrapper flex-grow-1 mr-2">
            <i aria-hidden="true" class="fas fa-search input-icon"></i>
            <input
              aria-describedby="searchIcon"
              placeholder="Search..."
              type="search"
              [formControl]="search"
            />
          </div>
        </div>
      </div>

      <div [ngBusy]="busy.loadUsers">
        <ag-grid-angular
          #agGrid
          class="ag-theme-material"
          [gridOptions]="gridOptions"
          [rowData]="userObservable | async"
        >
          <ag-grid-column
            field="user"
            headerName="Name"
            type="userColumn"
          ></ag-grid-column>
          <ag-grid-column
            field="role"
            headerName="Security"
            type="enumColumn"
            [cellRendererParams]="{ enum: officeUserRoles }"
            [width]="120"
          ></ag-grid-column>
          <ag-grid-column
            field="isPartner"
            headerName="Partner"
            type="booleanColumn"
            [maxWidth]="120"
            [minWidth]="120"
          ></ag-grid-column>
          <ag-grid-column
            field="isManager"
            headerName="Manager"
            type="booleanColumn"
            [maxWidth]="120"
            [minWidth]="120"
          ></ag-grid-column>
          <ag-grid-column
            field="isDefaultPartner"
            headerName="Default"
            type="booleanColumn"
            [maxWidth]="120"
            [minWidth]="120"
          ></ag-grid-column>
          <ag-grid-column
            type="optionsColumn"
            [cellRendererParams]="{ ngTemplate: optionsCell }"
            [maxWidth]="90"
            [minWidth]="90"
          ></ag-grid-column>
        </ag-grid-angular>

        <ng-template #optionsCell let-row>
          <div class="btn-mini-menu">
            <button
              class="btn-sm fas-btn-primary-ghost"
              title="Edit Practice User"
              type="button"
              (click)="editOfficeUser(row)"
            >
              <i aria-hidden="true" class="fas fa-edit"></i>
            </button>
            <button
              class="btn-sm fas-btn-ghost"
              title="Remove Practice User"
              type="button"
              (click)="removeOfficeUser(row)"
            >
              <i aria-hidden="true" class="fas fa-trash"></i>
            </button>
          </div>
        </ng-template>
      </div>
    </div>
  </div>

  <div class="spacer"></div>

  <crs-form-error [error]="error"></crs-form-error>

  <div class="btn-menu form-bottom-menu">
    <button class="btn btn-primary" type="submit" [promiseBtn]="busy.submit">
      Save and Close
    </button>
    <button class="btn btn-secondary" type="button" (click)="close()">
      Cancel
    </button>
    <button
      class="btn btn-outline-danger float-right"
      title="Delete"
      type="button"
      *ngIf="!isAdd && isAdmin"
      (click)="delete()"
    >
      <i aria-hidden="true" class="fas fa-trash"></i>
      Delete
    </button>
  </div>
</form>
