import { Component, Input, OnInit } from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { Observable, Subject } from 'rxjs';

import { TaxCodesTableComponent } from '../tax-codes-table/tax-codes-table.component';
import { TaxCode } from '../tax-code';
import { TaxCodeService } from '../tax-code.service';
import { SourceAccount } from '../../sourceAccounts';
import { MasterBasCode } from 'src/app/accounting/chart/master-bas-codes/master-bas-code';

export enum TaxCodeFormType {
  Edit = 'edit',
  Add = 'add',
}

@Component({
  selector: 'crs-tax-code-form',
  templateUrl: './tax-code-form.component.html',
})
export class TaxCodeFormComponent implements OnInit {
  @Input() id: string;
  @Input() set params(value: {
    fileId: string;
    postingAccounts: SourceAccount[];
    masterBasCodes: MasterBasCode[];
    taxCode: TaxCode;
    taxCodesTable: TaxCodesTableComponent;
  }) {
    this.masterBasCodes = value.masterBasCodes;
    this.fileId = value.fileId;
    this.postingAccounts = value.postingAccounts;
    this.taxCodesTable = value.taxCodesTable;

    if (value.taxCode) {
      this.form.patchValue({
        basCode: value.taxCode.basCode,
        description: value.taxCode.description,
        effective_Date: value.taxCode.effective_Date,
        isCapital: value.taxCode.isCapital,
        postingAccountNo: value.taxCode.postingAccountNo,
        rate: value.taxCode.rate,
        standardTaxCodeId: value.taxCode.standardTaxCodeId,
        taxCode: value.taxCode.taxCode,
      });

      const basCode = this.masterBasCodes.find(
        ({ basCode }) => basCode === this.form.get('basCode').value
      );
      this.isCapitalAllowed = basCode?.isCapitalAllowed ?? false;
    }
  }

  busy = {
    load: null,
    submit: null,
  };
  error: string = null;
  isAdd: boolean;
  isCustom: boolean = false;
  isCapitalAllowed: boolean;

  form = this.formBuilder.group({
    taxCode: [
      '',
      [
        Validators.required,
        Validators.maxLength(128),
        this.uniqueTaxCodeValidator(),
      ],
    ],
    description: ['', [Validators.required, Validators.maxLength(256)]],
    rate: [0, [Validators.required, Validators.min(0), Validators.max(100)]],
    effective_Date: [
      '2000-07-01T00:00:00',
      [Validators.required, Validators.maxLength(256)],
    ],
    postingAccountNo: [null],
    basCode: [null],
    masterTaxCodeId: [null],
    isCapital: [false],
  });

  masterBasCodes: MasterBasCode[];
  fileId: string;
  postingAccounts: SourceAccount[];
  submitButtonStream$ = new Subject();
  taxCodesTable: TaxCodesTableComponent;

  constructor(
    private activeModal: NgbActiveModal,
    private formBuilder: UntypedFormBuilder,
    private taxCodeService: TaxCodeService
  ) {}

  ngOnInit(): void {
    this.isAdd = this.id === TaxCodeFormType.Add;
    this.isCustom = this.form.controls['masterTaxCodeId'].value === null;
  }

  onClickClose(): void {
    this.activeModal.dismiss();
  }

  onValidSubmit(): void {
    this.submit$().subscribe(
      () => {},
      (err) => this.showError(err),
      () => this.activeModal.close()
    );
  }

  searchFn(term: string, item: any) {
    term = term.toLowerCase();
    return (
      item.accountNo.toLowerCase().indexOf(term) > -1 ||
      item.accountName.toLowerCase().indexOf(term) > -1
    );
  }

  searchBasCodesFn(term: string, item: any) {
    term = term.toLowerCase();
    return (
      item.basCode.toLowerCase().indexOf(term) > -1 ||
      item.description.toLowerCase().indexOf(term) > -1
    );
  }

  onChangeBasCode(event: MasterBasCode) {
    this.isCapitalAllowed = event?.isCapitalAllowed ?? false;

    if (!this.isCapitalAllowed) {
      this.form.patchValue({ isCapital: false });
    }
  }

  private submit$(): Observable<any> {
    const taxCode = new TaxCode(this.form.value);
    taxCode['fileId'] = this.fileId;

    return this.isAdd
      ? this.taxCodeService.postTaxCode$(taxCode)
      : this.taxCodeService.putTaxCode$(this.id, taxCode);
  }

  private showError(error): void {
    this.error = error;
  }

  private uniqueTaxCodeValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value === '') return null;
      let taxCode = new TaxCode({
        id: this.id,
        taxCode: control.value,
      });
      const invalid = this.taxCodesTable.isUniqueTaxCode(taxCode) === false;
      return invalid ? { duplicateTaxCode: true } : null;
    };
  }
}
