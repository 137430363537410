<div [formGroup]="detail">


  <div class="row" *ngIf="!file.entity">
    <div class="col-sm-6">
      <div class="form-group required">
        <label class="control-label">Entity</label>
        <ng-select [items]="entities" bindValue="id" bindLabel="legalName" formControlName="entityId" [clearable]="false"></ng-select>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-6">
      <div class="form-group required">
        <label class="control-label">Year</label>
        <ng-select [items]="years" bindValue="year" bindLabel="year" formControlName="year" [clearable]="false"></ng-select>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-6">
      <div class="form-group required">
        <label class="control-label">Report</label>
        <ng-select [items]="depreciationReportTypes | enumToArray" bindValue="value" bindLabel="name" formControlName="reportType" [clearable]="false"></ng-select>
      </div>
    </div>

    <div class="col-sm-6">
      <div class="form-group" *ngIf="detail.value.reportType < 3">
        <label class="control-label">Asset Groups</label>
        <crs-asset-group-select [multiple]="true" [fileId]="file.id" [entityId]="entityId" formControlName="assetGroups" placeholder="Show All"></crs-asset-group-select>
      </div>

      <div class="form-group required" *ngIf="detail.value.reportType == 3">
        <label class="control-label">Pool</label>
        <ng-select [items]="depreciationPools | enumToArray" bindValue="value" bindLabel="name" formControlName="pool" [clearable]="false"></ng-select>
      </div>

      <div class="form-group required" *ngIf="detail.value.reportType == 3">
        <label class="control-label">Depreciation Type</label>
        <ng-select [items]="depreciationTypes | enumToArray" bindValue="value" bindLabel="name" formControlName="depreciationType" [clearable]="false"></ng-select>
      </div>

      <div class="form-group" *ngIf="detail.value.reportType == 3">
        <div class="form-check">
          <input type="checkbox" class="form-check-input" formControlName="showPoolSummary">
          <label class="form-check-label">Includes Pool Summary</label>
        </div>
      </div>
    </div>
  </div>

</div>
