export class CarCostLimit {
  year: number;
  value: number;
  ruling: string;

  constructor(year: number, value: number, ruling?: string) {
    this.year = year;
    this.value = value;
    this.ruling = ruling;
  }
}

export class CarCostLimitManager {

  limits = [
    new CarCostLimit(2023, 64741),
    new CarCostLimit(2022, 60733),
    new CarCostLimit(2021, 59136),
    new CarCostLimit(2020, 57581),
    new CarCostLimit(2019, 57581, 'TD 2018/6'),
    new CarCostLimit(2018, 57581, 'TD 2017/18'),
    new CarCostLimit(2017, 57581, 'TD 2016/8'),
    new CarCostLimit(2016, 57466, 'TD 2015/16'),
    new CarCostLimit(2015, 57466, 'TD 2014/17'),
    new CarCostLimit(2014, 57466, 'TD 2013/15'),
    new CarCostLimit(2013, 57466),
    new CarCostLimit(2012, 57466),
    new CarCostLimit(2011, 57466),
    new CarCostLimit(2010, 57180),
    new CarCostLimit(2009, 57180),
    new CarCostLimit(2008, 57123),
    new CarCostLimit(2007, 57009),
    new CarCostLimit(2006, 57009),
    new CarCostLimit(2005, 57009),
    new CarCostLimit(2004, 57009),
    new CarCostLimit(2003, 57009),
    new CarCostLimit(2002, 55134),
    new CarCostLimit(2001, 55134),
    new CarCostLimit(2000, 55134),
    new CarCostLimit(1999, 55134),
    new CarCostLimit(1998, 55134),
    new CarCostLimit(1997, 55134),
    new CarCostLimit(1996, 52912),
    new CarCostLimit(1995, 51271),
    new CarCostLimit(1994, 48415),
    new CarCostLimit(1993, 47280),
    new CarCostLimit(1992, 45462),
    new CarCostLimit(1991, 45056),
    new CarCostLimit(1990, 42910),
    new CarCostLimit(1989, 39331),
    new CarCostLimit(1988, 34775),
    new CarCostLimit(1987, 29646),
    new CarCostLimit(1986, 26660)
  ];

  getCostLimit(date: Date) {
    if (!date) return null;
    const year = this.getYear(date);
    return this.limits.find(l => l.year === year);
  }

  private getYear(date: Date) {
    const year = date.getUTCFullYear();
    const month = date.getUTCMonth();
    return year + (month <= 6 ? 1 : 0);
  }

}
