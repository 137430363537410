export enum DepreciationReportType {
  FullSummary = 0,
  Taxation = 1,
  Accounting = 2,
  Pooling = 3,
  TaxDisclosures = 4
}

/// Depreciation reports available in the main report template system
export enum DepreciationReportTypeInReport {
  FullSummary = 0,
  Taxation = 1,
  Accounting = 2,
  Pooling = 3
}
