import {
  AccountDetailRendererComponent,
  AccountHeaderRendererComponent,
  AccountHeadersCountRendererComponent,
  BadgeRendererComponent,
  CheckRendererComponent,
  ClassificationRendererComponent,
  DateRendererComponent,
  DivisionRendererComponent,
  DollarRendererComponent,
  EnumRendererComponent,
  HyperlinkRendererComponent,
  NumberRendererComponent,
  SourceRendererComponent,
  SourceTypeRendererComponent,
  TemplateRendererComponent,
  UserRendererComponent,
  SelectRendererComponent,
} from './';

export const renderers = {
  badgeRenderer: BadgeRendererComponent,
  checkRenderer: CheckRendererComponent,
  dateRenderer: DateRendererComponent,
  dollarRenderer: DollarRendererComponent,
  enumRenderer: EnumRendererComponent,
  hyperlinkRenderer: HyperlinkRendererComponent,
  numberRenderer: NumberRendererComponent,
  sourceRenderer: SourceRendererComponent,
  templateRenderer: TemplateRendererComponent,
  userRenderer: UserRendererComponent,
  selectRenderer: SelectRendererComponent,
};

export const accountingRenderers = {
  accountDetailRenderer: AccountDetailRendererComponent,
  accountHeaderRenderer: AccountHeaderRendererComponent,
  accountHeadersCountRenderer: AccountHeadersCountRendererComponent,
  checkRenderer: CheckRendererComponent,
  classificationRenderer: ClassificationRendererComponent,
  dateRenderer: DateRendererComponent,
  divisionRenderer: DivisionRendererComponent,
  dollarRenderer: DollarRendererComponent,
  enumRenderer: EnumRendererComponent,
  hyperlinkRenderer: HyperlinkRendererComponent,
  numberRenderer: NumberRendererComponent,
  selectRenderer: SelectRendererComponent,
  sourceRenderer: SourceRendererComponent,
  sourceTypeRenderer: SourceTypeRendererComponent,
  templateRenderer: TemplateRendererComponent,
  userRenderer: UserRendererComponent,
};
