import { ModalService } from 'src/app/core';
import { AuthInitService } from './core/services/auth-init.service';
import { Component, HostListener, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd, NavigationStart } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { filter, map, mergeMap, tap } from 'rxjs/operators';

@Component({
  selector: 'crs-app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'Access Ledger';
  authInitialising = false;

  constructor(private titleService: Title,
    private router: Router,
    private route: ActivatedRoute,
    private authInitService: AuthInitService,
    public modalService: ModalService) {
  }

  ngOnInit() {

    this.authInitialising = true;
    this.authInitService.init().catch(() => true).then(() => this.authInitialising = false);

    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(e => e as NavigationEnd),
      tap(() => this.modalService.closeOverlay()),
      // tap(e => console.log('Routed to ' + e.url, e, this.router.routerState.snapshot)),
      map(() => this.route),
      map((route) => {
        while (route.firstChild) route = route.firstChild;
        return route;
      }),
      filter(route => route.outlet === 'primary')
    )
    .subscribe(route => this.titleService.setTitle(`Access Ledger ${this.getTitle(route)}`));
  }

  private getTitle(route: ActivatedRoute) {
    let title: string = null;
    do {
      if (route && route.snapshot.data && route.snapshot.data && route.snapshot.data['title']) {
        title = route.snapshot.data['title'];
      }
      route = route.parent;
    } while (route.parent && !title);

    if (!title) return '';
    else return ` | ${title}`;

  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'Escape') this.modalService.closeOverlay();
  }


}
