import { Dataset } from '../../datasets';
import { Division } from '../../setup/divisions';
import { TradingAccount } from '../../setup/tradingAccounts';

export enum ColumnType {
  Text = 0,
  Number = 1,
  Date = 2,
  Boolean = 3,
  AutoTotal = 4,
}

export enum ColumnSpreadBy {
  Division,
  TradingAccount,
  Month,
  Quarter,
}

export enum ConsolidationMode {
  None = 0,
  ConsolidatedBalances = 1,
  EliminationEntries = 2,
}

export enum ColumnCalculationType {
  Variance = 0,
  PercentVariance = 1,
  PercentOfSales = 2,
}

export class ReportTemplateColumn {
  id: string;
  sortOrder: number;
  columnType: ColumnType;
  dataset: Dataset;
  headerText: string;
  periodText: string;
  signText: string;
  spreadby: ColumnSpreadBy;
  division: Division;
  tradingAccount: TradingAccount;
  startDate: Date;
  endDate: Date;
  consolidationMode: ConsolidationMode;
  calculationType: ColumnCalculationType;

  constructor(data) {
    Object.assign(this, data);
    if (data.dataset) this.dataset = new Dataset(data.dataset);
    if (data.division) this.division = new Division(data.division);
    if (data.tradingAccount)
      this.tradingAccount = new TradingAccount(data.tradingAccount);
  }
}

export class ReportTemplateColumnModel {
  id: string;
  columnType: ColumnType;
  datasetId: string;
  headerText: string;
  periodText: string;
  signText: string;
  spreadBy: ColumnSpreadBy;
  divisionId: string;
  tradingAccountId: string;
  startDate: Date;
  endDate: Date;
  consolidationMode: ConsolidationMode;
  calculationType: ColumnCalculationType;

  constructor(data) {
    // Object.assign(this, data); Because potentially a lot of excess data is sent in requests for updates, we assign this object manually
    this.id = data.id;
    this.columnType = data.columnType;
    this.datasetId = data.datasetId;
    this.headerText = data.headerText;
    this.periodText = data.periodText;
    this.signText = data.signText;
    this.spreadBy = data.spreadBy;
    this.divisionId = data.divisionId;
    this.tradingAccountId = data.tradingAccountId;
    this.startDate = data.startDate;
    this.endDate = data.endDate;
    this.consolidationMode = data.consolidationMode;
    this.calculationType = data.calculationType;

    if (data.dataset) this.datasetId = data.dataset.id;
    if (data.division) this.divisionId = data.division.id;
    if (data.tradingAccount) this.tradingAccountId = data.tradingAccount.id;
  }
}
