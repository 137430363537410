import { AssetsContextService } from './assets-context.service';
import { DepreciationYear } from './depreciation-years/depreciation-year';
import { Entity } from 'src/app/firm';
import { File } from '../';

export class AssetsContext {
  file: File;
  entity: Entity;

  year: DepreciationYear;

  private _years: DepreciationYear[];
  private _yearsDescending: DepreciationYear[];

  get years() {
    return this._years;
  }
  get yearsDescending() {
    return this._yearsDescending;
  }

  getStarted: boolean;
  error: boolean;

  constructor(file: File) {
    this.file = file;
    if (file && file.isConsolidated) this.entity = file.entities[0];
    else this.entity = file ? file.entity : null;
  }

  public static errorContext() {
    const context = new AssetsContext(null);
    context.error = true;
    return context;
  }

  public setYears(years: DepreciationYear[]) {
    this._years = years.sort((a, b) => a.year - b.year);
    this._yearsDescending = Array.from(years).sort((a, b) => b.year - a.year);
  }
}
